import parse from 'html-react-parser';
import Style from './styles';

interface CardProps {
  b_container: string;
  title: string;
  img?: string;
  imgWebp?: string;
  alt?: string;
}

const CardImage = ({
  title,
  img,
  imgWebp,
  alt,
  b_container
}: CardProps): JSX.Element => {
  return (
    <Style>
      <h1 b-container={`${b_container}`} b-str='image_titulo'>
        {parse(title)}
      </h1>
      {img ? (
        <picture className='image-box'>
          <source srcSet={imgWebp} type='image/webp' />
          <source srcSet={img} type='image/jpeg' />
          <img src={img} alt={alt} />
        </picture>
      ) : (
        ''
      )}
    </Style>
  );
};

export default CardImage;
