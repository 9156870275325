import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CookieBoxStyle from './styles';

const CookieBox = (): JSX.Element => {
  const [Accepted, setAccepted] = useState(true);
  const { t } = useTranslation();

  const onAccept = () => {
    localStorage.setItem('termos_aceitos', 'true');
    setAccepted(true);
  };

  useEffect(() => {
    setAccepted(false);
    if (localStorage.getItem('termos_aceitos')) {
      setAccepted(true);
    }
  }, []);
  if (!Accepted) {
    return (
      <CookieBoxStyle>
        <span>
          {parse(
            t(
              'Utilizamos cookies para garantir a melhor experiência em nosso site. Se você continuar a usar este site, assumiremos que você está satisfeito com ele. <a href="/privacy-policy" target="_blank" rel="noreferrer">Política de Privacidade</a>'
            )
          )}
        </span>
        <div onClick={onAccept} className='btn-accept'>
          {t('Ok')}
        </div>
      </CookieBoxStyle>
    );
  } else {
    return <></>;
  }
};

export default CookieBox;
