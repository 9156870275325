import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';

import { Link } from 'react-router-dom';
import { HeaderIndexAltStyle } from './styles';

const HeaderIndexAlt = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <HeaderIndexAltStyle>
      <img
        style={{
          bottom: 0,
          position: 'absolute',
          width: '100%',
          zIndex: 0
        }}
        src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FGrupo%20de%20m%C3%A1scara%201%402x.png?alt=media&token=d0007c42-58f6-40f1-a80a-4ea85e8abb09'
        alt='imagem de fundo'
      />
      <img
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 0
        }}
        src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fsvg_wave_transparent_rsz.svg?alt=media&token=718586c9-0e0f-480c-953f-1c3e3a5fa3e4'
        alt='imagem de fundo 2'
      />
      <Carousel
        showStatus={false}
        autoPlay
        showThumbs={false}
        infiniteLoop
        className='carousel'>
        <div className='hero-container'>
          <div className='hero-info'>
            <div
              b-container={`header_alt`}
              b-str='titulo'
              className='hero-title'>
              {t('#1° em Gamificação para Vendas do Brasil')}
            </div>
            <div
              b-container={`header_alt`}
              b-str='subtitulo'
              className='hero-text'>
              {t(
                'Conheça a plataforma Play2sell e aumente a conversão de vendas de seu time comercial'
              )}
            </div>
            <div className='hero-buttons'>
              <Link to={'/contact'} className='btn-hero'>
                {t('Saiba Mais').toUpperCase()}
              </Link>
            </div>
          </div>
          <div className='hero-image'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FBanners-topo-01.png?alt=media&token=512d28f8-0cde-408a-91dc-0d58f0c837e8'
              alt='imagem de prêmios'
            />
          </div>
        </div>
        <div className='hero-container'>
          <div className='hero-info'>
            <div
              b-container={`header_alt`}
              b-str='titulo'
              className='hero-title'>
              {t('A Plataforma financeira da Play2sell')}
            </div>
            <div
              b-container={`header_alt`}
              b-str='subtitulo'
              className='hero-text'>
              {t(
                'Com a P2S Pay você faz premiações em dinheiro e split de pagamento.'
              )}
            </div>
            <div className='hero-buttons'>
              <Link
                to={'https://www.p2spay.com.br/'}
                className='btn-hero'
                target='_blank'>
                {t('Saiba Mais').toUpperCase()}
              </Link>
            </div>
          </div>
          <div className='hero-image'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FBanners-topo-p2spay.png?alt=media&token=1b5d3af3-0be5-4a6d-9fbc-b2cc8177f8ac'
              alt='imagem de p2spay'
            />
          </div>
        </div>
        <div className='hero-container'>
          <div className='hero-info'>
            <div
              b-container={`header_alt`}
              b-str='titulo'
              className='hero-title'>
              {t('Dashboard: sua estratégia na ponta dos dedos')}
            </div>
            <div
              b-container={`header_alt`}
              b-str='subtitulo'
              className='hero-text'>
              {t(
                'Gerencie a plataforma e tome suas decisões com dados em tempo real.'
              )}
            </div>
            <div className='hero-buttons'>
              <Link
                to={'https://admin.play2sell.com/login'}
                className='btn-hero'
                target='_blank'>
                {t('Saiba Mais').toUpperCase()}
              </Link>
            </div>
          </div>
          <div className='hero-image'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FBanners-topo-02.png?alt=media&token=35e0b8ba-87e9-4d6c-be15-16241be06060'
              alt='imagem de prêmios'
            />
          </div>
        </div>
        <div className='hero-container'>
          <div className='hero-info'>
            <div
              b-container={`header_alt`}
              b-str='titulo'
              className='hero-title'>
              {t('PlayClub, o painel de prêmios da Play2sell')}
            </div>
            <div
              b-container={`header_alt`}
              b-str='subtitulo'
              className='hero-text'>
              {t(
                'Com o PlayClub você não vai perder tempo com a logística de entrega de prêmios para sua equipe.'
              )}
            </div>
            <div className='hero-buttons'>
              <Link
                to={'https://playclub.play2sell.com/'}
                className='btn-hero'
                target='_blank'>
                {t('Saiba Mais').toUpperCase()}
              </Link>
            </div>
          </div>
          <div className='hero-image'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fimg_banner_site_rsz.png?alt=media&token=c585c94a-5522-4185-8035-0d27a6b71152'
              alt='imagem de prêmios'
            />
          </div>
        </div>
      </Carousel>
    </HeaderIndexAltStyle>
  );
};

export default HeaderIndexAlt;
