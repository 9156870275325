import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardRowsContainer from '../../components/cardRowsContainer';
import Clientes from '../../components/clientes';
import Depoimentos from '../../components/depoimentos';
import ExperienceContainer from '../../components/experienceContainer';
import Footer from '../../components/footer';
import HeaderIndexAlt from '../../components/headerIndexAlt';
import HeaderNav from '../../components/headerNav';
import InfoApp from '../../components/infoApp';
import { RedCircle } from '../../components/svg_list';
import Topovideo from '../../components/topoVideo';
import VideoContainer from '../../components/videoContainer';
import i18n from '../../utils/translate';

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Gamificação para Equipes de Vendas - Play2sell')}</title>
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <meta
          property='og:title'
          content='Gamificação para Equipe de Vendas - Play2sell'
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FPS2_logo.png?alt=media&token=a30df6ab-87c2-42ba-8eab-c1bc9a314d2d'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://play2sell.com/' />
        <meta
          name='twitter:title'
          content='Gamificação para Equipe de Vendas - Play2sell'
        />
        <meta
          name='twitter:description'
          content='Play2sell é a única plataforma de treinamento de vendas gamificado. Especialistas em metodologias de gamificação para o mercado de vendas.'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <HeaderIndexAlt />
        <RedCircle
          style={{
            position: 'absolute',
            left: '60%',
            bottom: '50px',
            animation:
              '30s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s infinite normal none running movingaround',
            zIndex: 9
          }}
        />
        <Topovideo />
        <VideoContainer />
        <Clientes />
        <CardRowsContainer />
        <InfoApp />
        {/* <BlogContainer /> */}
        <ExperienceContainer />
        <Depoimentos />
      </main>
      <Footer />
    </>
  );
};

export default Home;
