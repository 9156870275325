import Styled from 'styled-components';

const DepoimentosStyle = Styled.div`
  position: relative;
  margin: 160px auto;
  margin-top: 0;
  .bg-container {
    background: #FDF4F5;
    position: relative;
    overflow: hidden;
  }
  .depoimentos-container {
    margin: 0 auto;
    padding: 140px 0;
    padding-bottom: 260px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .text-container {
      position: relative;
    }
  }
  h1 {
    font-size: 50px;
    font-weight: 100;
    color: #e20c18;
    text-align: center;
  }
  h4 {
    font-size: 18px;
    font-weight: 400;
    color: #7283ba;
    text-align: center;
    margin-top: 16px;
    white-space: pre-wrap;
    margin-bottom: 70px;
  }
  .cards-container {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    .depoimento-card {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      width: 30%;
      min-width: 300px;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10px;
      .card-message {
        background: #fff;
        color: #7283BA;
        white-space: pre-wrap;
        padding: 20px;
        border: 1px solid #D4DFFF;
        border-radius: 4px;
        margin-bottom: 20px;
      }
      .card-info {
        display: flex;
        align-items: center;
        flex-direction: row;
        .card-image {
          width: 60px;
          height: 60px;
          background-color: white;
          border-radius: 50%;
          margin-right: 20px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .card-container{
          display: flex;
          flex: 1;
          flex-direction: column
        }
        .card-name {
          color:#09206A;
          font-size: 18px;
        }
        .card-subtitle {
          color: #7283BA;
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    .cards-container {
      padding: 0 35px;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media screen and (max-width: 560px) {
    .depoimentos-container {
      padding: 75px 0;
    }
    h1 {
      font-size: 30px;
    }
    .cards-container {
      padding: 0 35px;
      grid-template-columns: 1fr;
    }
  }
`;

export default DepoimentosStyle;
