import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Style from './styles';

const Integrador = (): JSX.Element => {
  const listIntegrations = [
    {
      name: 'Bitrix24_Logo.png',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FBitrix.png?alt=media&token=0f9462cd-f7e3-4127-830f-04fffb01a59d',
      link: '/integration-bitrix'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FLogoAzul-sigavi.png?alt=media&token=44a4ac56-7153-4a95-93e6-bbf1197fd495',
      name: '2 Sigavi',
      link: '/integration-sigavi'
    },
    {
      link: '/integration-vista',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FVista135px.png?alt=media&token=ae50965e-d898-428e-acf9-fb2f8160e6f4',
      name: 'Vista'
    },
    {
      name: 'CV',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2Fdownloadcv.png?alt=media&token=498ac7fe-a797-4488-80b2-390742bdff34',
      link: '/integration-cv'
    },
    {
      name: 'Hypnobox',
      link: '/integration-hypnobox',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FLogo-hypnobox.png?alt=media&token=e0a1b1e7-48af-4f88-81d8-fb1eb0417c38'
    }
  ];

  const settings = {
    slidesToShow: 5,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          infinite: true,
          centerMode: true,
          centerPadding: '60px'
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '80px'
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '50px'
        }
      }
    ]
  };

  return (
    <Style>
      <div className='integrador'>
        <Slider {...settings} className='imgs'>
          {listIntegrations.map((logo: any, idx: number) => (
            <div key={idx}>
              <div className='img'>
                <Link to={logo.link} target='_blank' rel='noreferrer'>
                  <img src={logo.url} alt={`Imagem${logo.uid}`} />
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Style>
  );
};

export default Integrador;
