import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import TeamCard from '../../components/teamCard';
import i18n from '../../utils/translate';
import StyleMain from './styles';

const SobreAEmpresa = (): JSX.Element => {
  const b_ContainerName = 'sobre';
  const { t } = useTranslation();
  const listTeam: {
    gif: string;
    name: string;
    image: string;
    job: string;
    url: string;
  }[] = [
    {
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2FFelipe.gif?alt=media&token=cc8eb3a5-a513-4728-8eda-e0f421b2bafd',
      name: 'Felipe dos Santos',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FFelipe%20dos%20Santos.jpg?alt=media&token=7f88b1ae-fe8e-4d54-8baf-bf950a80237d',
      job: t('CEO'),
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FFelipe%20dos%20Santos.jpg?alt=media&token=7f88b1ae-fe8e-4d54-8baf-bf950a80237d'
    },
    {
      job: t('Conteúdo'),
      name: 'Gustavo Wolf',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fgif_gustavo_original.gif?alt=media&token=20648a51-4fca-433a-b907-9f0bf77aecd1',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fgustavo_original_PB.png?alt=media&token=49e0f656-6391-4ad5-8bc5-79438a93c963',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fgustavo_original_PB.png?alt=media&token=49e0f656-6391-4ad5-8bc5-79438a93c963'
    },
    {
      job: t('Financeiro'),
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FBeatriz-Hass.jpg?alt=media&token=28405189-6f46-495e-a293-09db7666ad33',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FBeatriz-Hass.jpg?alt=media&token=28405189-6f46-495e-a293-09db7666ad33',
      name: 'Beatriz Hass',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2Fbeatriz.gif?alt=media&token=48cefa39-aa97-4f3d-9ffd-5fe61bcf9a1e'
    },
    // {
    //   image:
    //     'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fpb_pepita.jpg?alt=media&token=fea6b084-026a-4a95-b6f5-712a8809da56',
    //   job: t('RH'),
    //   gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fgif_pepita.gif?alt=media&token=3d097a3c-60cc-4e2e-b958-2a550fee5afb',
    //   url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fpb_pepita.jpg?alt=media&token=fea6b084-026a-4a95-b6f5-712a8809da56',
    //   name: 'Pepita Benjamin'
    // },
    {
      name: 'Giulliano Dias',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FGiu-2.jpg?alt=media&token=52e6c4c6-8991-44c4-8603-e813cf846d5e',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2FGiu.gif?alt=media&token=e8fe9d1b-4946-4e8a-96c9-9cf7a5586706',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FGiu-2.jpg?alt=media&token=52e6c4c6-8991-44c4-8603-e813cf846d5e',
      job: t('Tecnologia')
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fricardo-2.jpg?alt=media&token=5cf6dee7-555c-4745-8289-c5d3ca0fe1c7',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fricardo-2.jpg?alt=media&token=5cf6dee7-555c-4745-8289-c5d3ca0fe1c7',
      name: 'Ricardo Noguti',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2F3.-SITE_GIF_Ricardo.gif?alt=media&token=ff3134fb-24bf-4d73-af15-9562746ddf57',
      job: t('Tecnologia')
    },
    {
      job: t('Tecnologia'),
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Ffabio-2.jpg?alt=media&token=06b8ab30-7909-4b9e-a621-4100ec443725',
      name: 'Fábio Júnior',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2FSITE_GIF_Fabio.gif?alt=media&token=0ae42b95-1839-4516-98e4-edb4d0e8843a',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Ffabio-2.jpg?alt=media&token=06b8ab30-7909-4b9e-a621-4100ec443725'
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FFran-2.jpg?alt=media&token=d23ed4eb-fbac-4a97-bdb2-5690a2ec8e89',
      name: 'Fran Bernardes',
      job: t('Tecnologia'),
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FFran-2.jpg?alt=media&token=d23ed4eb-fbac-4a97-bdb2-5690a2ec8e89',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2FSITE_GIF_Fran-1.gif?alt=media&token=5e392c4a-d3fe-4aaa-9c2d-a3e0faac42b8'
    },
    {
      name: 'Pedro Guimarães',
      url: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fpedroooo%20certooo.jpg?alt=media&token=4edc265e-2b05-4438-b4e5-878be18a81b5',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fgifpedrocurto.gif?alt=media&token=bab9b4e9-3a84-4463-8cb8-1bf9fd9c7a39',
      job: t('Tecnologia'),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fpedroooo%20certooo.jpg?alt=media&token=4edc265e-2b05-4438-b4e5-878be18a81b5'
    },
    {
      job: t('Inteligência Artificial'),
      name: 'Nobre de Araújo',
      url: '',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2FSITE_GIF_Nobre.gif?alt=media&token=4e18e866-967b-4fcf-b894-e6d12dff94a0',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fnobre_original_PB.png?alt=media&token=1cd5fc74-3fad-4a5d-aed8-7b890968bef6'
    },
    {
      name: 'Barthyra Garcia',
      job: t('Head de Relacionamento'),
      url: '',
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2FBarthyraGarcia.jpeg?alt=media&token=3a27fc3d-a8d0-4a93-8334-37d4fac8ff9e',
      gif: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/colaboradores%2Fgifs%2FBa_gif2-ezgif.com-rotate%20(1).gif?alt=media&token=6c7a8a8d-6d3f-4551-b7ad-56c1c2eb4566'
    }
  ];

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>
          {t(
            'Plataforma para Gamificação de Treinamentos de Vendas - Play2sell'
          )}
        </title>
        <meta
          name='description'
          content='Play2sell gamificação de treinamentos de vendas | Saiba como melhorar os resultados e aumentar a motivação dos seus vendedores.'
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link rel='canonical' href='https://play2sell.com/about/' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Plataforma para Gamificação de Treinamentos de Vendas - Play2sell'
        />
        <meta
          property='og:description'
          content='Play2sell gamificação de treinamentos de vendas | Saiba como melhorar os resultados e aumentar a motivação dos seus vendedores.'
        />
        <meta property='og:url' content='https://play2sell.com/about/' />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-04-26T12:45:44-03:00' />

        <meta
          name='twitter:title'
          content='Plataforma para Gamificação de Treinamentos de Vendas - Play2sell'
        />
        <meta
          name='twitter:description'
          content='Play2sell gamificação de treinamentos de vendas | Saiba como melhorar os resultados e aumentar a motivação dos seus vendedores.'
        />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_ContainerName}
        title={t('Conheça a Play2sell')}
        subtitulo={t('Sobre a Empresa')}
      />
      <StyleMain className='container-main'>
        <CardText
          b_container={b_ContainerName}
          b_str='1'
          title={t('Breve história') || ''}
          text={
            t(
              'A Play2sell atua com gamificação oferecendo uma opção divertida de treinamento de vendas e motivacionais. Idealizada em San Francisco, na Califórnia, em 2017 quando <a href="https://www.linkedin.com/in/felipeplay2sell/" target="_blank" rel="noreferrer">Felipe dos Santos</a>, economista e empreendedor serial foi morar no Vale do Silício, primeiramente em busca de inspiração para seu próximo negócio, logo depois de cofundar e vender duas empresas de software para o mercado imobiliário.\n\nAfinal, não poderia encontrar lugar melhor para inovar, não é mesmo?'
            ) || ''
          }
        />
        <CardText
          b_container={b_ContainerName}
          b_str='2'
          title={
            t(
              'Trazendo a proposta da gamificação de treinamentos para o Brasil'
            ) || ''
          }
          text={
            t(
              'Ao se graduar no programa de aceleração do <a href="https://fi.co/insight/tremendous-growth-from-founder-institute-alumni-and-more-highlights-from-an-epic-2018?fbclid=IwAR34009aOI8-sZSqxJItDH2r8VU1Ew_rGPgedMI3p6SqxoI-84W4RXmOCmM" target="_blank" rel="noreferrer">Founder Institute</a>, a Play2sell iniciou suas atividades sendo a startup que nesse ínterim, recebeu uma primeira rodada de investimentos mais rápido. Além da <a href="http://jupter.co/" target="_blank" rel="noreferrer">Jupter</a>, <a href="https://www.nb.capital/" target="_blank" rel="noreferrer">NB Capital</a> e <a href="http://laasoc.com/" target="_blank" rel="noreferrer">LAAS</a  >, os anjos da Resh – um grupo da <a href="http://abmi.org.br/" target="_blank" rel="noreferrer">ABMI</a> – também participaram deste round.\n\nO objetivo antes de tudo, era validar o produto e o mercado, além de alcançar o ponto de equilíbrio (break even). Veja a <a href="https://www.startse.com/artigos/pesquisa-quem-investe-em-startups-no-brasil/" target="_blank" rel="noreferrer">matéria da StartSe</a>. Logo após alcançar os objetivos traçados para o primeiro ano, a empresa imediatamente fechou o segundo round de investimentos, com a participação da NB Capital e LAAS (follow on), a <a href="https://www.bossainvest.com/" target="_blank" rel="noreferrer">Bossa Nova Investimentos</a>, e um grupo de investidores anjo.\n\nEm primeiro lugar a meta é aprofundar a gamificação do treinamento de vendas no mercado imobiliário. Na sequência expandir para novos segmentos. Veja a <a href="https://startupi.com.br/play2sell-startup-que-fornece-treinamento-de-vendedores-por-meio-de-games-capta-r-2-milhoes/" target="_blank" rel="noreferrer">matéria da Startup.</a>'
            ) || ''
          }
        />
        <CardText
          b_container={b_ContainerName}
          b_str='3'
          title={t('Inovação e conscientização através da gamificação') || ''}
          text={
            t(
              'Com a crise do Coronavírus, a Play2sell criou um game gratuito para apoiar a população na prevenção do vírus. Ao mesmo tempo o game também ajuda na identificação de fake news. Veja aqui uma da matérias à respeito, publicada pela <a href="https://forbes.com.br/forbes-tech/2020/03/como-o-surto-de-coronavirus-pode-afetar-o-futuro-das-startups/" target="_blank" rel="noreferrer">Forbes</a>.'
            ) || ''
          }
        />
        <CardText
          b_container={b_ContainerName}
          b_str='4'
          title={t('Propósito') || ''}
          text={
            t(
              'A Play2sell é uma EdTech de Gamificação que existe para transformar a educação. Reinventando treinamentos tradicionais e por outro lado desburocratizando conteúdos com games divertidos e engajadores, fazendo com que aprender seja acima de tudo uma brincadeira motivadora!'
            ) || ''
          }
        />
        <CardText
          b_container={b_ContainerName}
          b_str='5'
          title={`${t('Contamos com um grupo forte de profissionais')}:`}
          text={
            t(
              'Focados em criar soluções inovadoras para os usuários se divertirem enquanto se capacitam através da <a href="/contact" target="_blank" rel="noreferrer">gamificação de treinamentos de vendas.</a>'
            ) || ''
          }
        />
        <div className='container-colaboradores'>
          {listTeam
            .sort((a: any, b: any) => {
              if (a.job < b.job) {
                return -1;
              }
              if (a.job > b.job) {
                return 1;
              }
              return 0;
            })
            .sort((a: any) => {
              if (a.job === 'CEO') {
                return -1;
              }
              return 0;
            })
            .map((member: any) => (
              <TeamCard
                name={member.name}
                job={member.job}
                img={member.image}
                gif={member.gif}
                key={member.name + member.job}
              />
            ))}
        </div>
      </StyleMain>
      <Footer />
    </>
  );
};
export default SobreAEmpresa;
