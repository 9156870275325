import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { CursorIcon, PhoneIcon, ShieldIcon, ViewIcon } from '../svg_icons';
import InfoAppStyle from './styles';

const InfoApp = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  }, []);

  const CardInfo = (props: any) => {
    return (
      <div className='card-info'>
        <div className='card-image'>{props.svg}</div>
        <div
          b-container={`info_app`}
          b-str={props.strTitle}
          className='card-title'>
          {props.title}
        </div>
        <div
          b-container={`info_app`}
          b-str={props.strText}
          className='card-text'>
          {props.text}
        </div>
      </div>
    );
  };

  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    infinite: true,
    centerMode: true,
    centerPadding: '45px',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 450,
        settings: {
          centerPadding: '45px'
        }
      },
      {
        breakpoint: 390,
        settings: {
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 350,
        settings: {
          centerPadding: '15px'
        }
      }
    ]
  };
  return (
    <InfoAppStyle className='container-narrow'>
      <div className='info-app-container'>
        <h1 b-container={`info_app`} b-str='titulo'>
          {t('Por que o Play2sell GO?')}
        </h1>
        <h4 b-container={`info_app`} b-str='subtitulo'>
          {`${t(
            'Descubra porque o Play2sell GO é o aplicativo de gamificação preferido para treinamentos de vendas'
          )}:`}
        </h4>
        {isMobile ? (
          <div className='cards-container'>
            <Slider {...settings}>
              <CardInfo
                svg={<ShieldIcon />}
                title={t('Duelos')}
                text={parse(
                  t(
                    '<div>Imagine seus vendedores desafiando os colegas para ver quem acerta mais perguntas no game? Isso que é motivação para vendedores!<br></div>'
                  )
                )}
              />
              <CardInfo
                svg={<CursorIcon />}
                title={t('Jogos Dinâmicos')}
                text={parse(
                  t(
                    'Jogar mais de uma vez o mesmo jogo nunca será igual! A cada vez que seu time jogar, a experiência será única, e as perguntas serão diferentes. O game é viciante!<br>'
                  )
                )}
              />
              <CardInfo
                svg={<ViewIcon />}
                title={t('Mundo real')}
                text={parse(
                  t(
                    'Não basta o vendedor dominar as informações e não aplicá-las. Por isto criamos as missões no mundo real. Através da integração com o seu CRM, pontuamos as atividades dos vendedores<br>'
                  )
                )}
              />
              <CardInfo
                svg={<PhoneIcon />}
                title={t('Aprenda Mais')}
                text={t(
                  'Ao acertar a resposta das perguntas, seu vendedor receberá uma dica extra para suas próximas jogadas, aprendendo cada vez mais!'
                )}
              />
            </Slider>
          </div>
        ) : (
          <div className='cards-container'>
            <CardInfo
              svg={<ShieldIcon />}
              title={t('Duelos')}
              text={parse(
                t(
                  '<div>Imagine seus vendedores desafiando os colegas para ver quem acerta mais perguntas no game? Isso que é motivação para vendedores!<br></div>'
                )
              )}
              strTitle='card_title_1'
              strText='card_text_1'
            />
            <CardInfo
              svg={<CursorIcon />}
              title={t('Jogos Dinâmicos')}
              text={t(
                'Jogar mais de uma vez o mesmo jogo nunca será igual! A cada vez que seu time jogar, a experiência será única, e as perguntas serão diferentes. O game é viciante!'
              )}
              strTitle='card_title_2'
              strText='card_text_2'
            />
            <CardInfo
              svg={<ViewIcon />}
              title={t('Mundo real')}
              text={parse(
                t(
                  'Não basta o vendedor dominar as informações e não aplicá-las. Por isto criamos as missões no mundo real. Através da integração com o seu CRM, pontuamos as atividades dos vendedores<br>'
                )
              )}
              strTitle='card_title_3'
              strText='card_text_3'
            />
            <CardInfo
              svg={<PhoneIcon />}
              title={t('Aprenda Mais')}
              text={t(
                'Ao acertar a resposta das perguntas, seu vendedor receberá uma dica extra para suas próximas jogadas, aprendendo cada vez mais!'
              )}
              strTitle='card_title_4'
              strText='card_text_4'
            />
          </div>
        )}
      </div>
    </InfoAppStyle>
  );
};

export default InfoApp;
