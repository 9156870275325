import parse from 'html-react-parser';
import Style from './styles';

interface CardNumberProps {
  b_container: string;
  b_str: string;
  title: string;
  text: string;
}

const CardNumber = ({
  title,
  text,
  b_container,
  b_str
}: CardNumberProps): JSX.Element => {
  return (
    <Style>
      <h3 b-container={`${b_container}`} b-str={`card_titulo_${b_str}`}>
        {title && parse(title)}
      </h3>
      <p b-container={`${b_container}`} b-str={`card_texto_${b_str}`}>
        {text && parse(text)}
      </p>
    </Style>
  );
};

export default CardNumber;
