import Styled from 'styled-components';

export const Backdrop = Styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000060;
  z-index: 0;
`;

const MenuShadow = Styled.div`
  height: 100%;
  background: #fff;
  box-shadow: #000 1px 1px 10px 2px;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  max-width: 430px;
  padding: 20px;
  z-index: 1;
  transform: translateX(110%);
  transition: 400ms ease-in-out;
  &.open {
    transform: none;
  }
`;

const SideDrawerStyle = Styled.nav`
  height: 100%;
  background: ${props => props.theme.colors.primary};
  box-shadow: #000 1px 1px 10px 2px;
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 400px;
  padding: 20px;
  z-index: 2;
  transform: translateX(110%);
  transition: 300ms ease-in-out;
  &.open {
    transform: none;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    li {
      position: relative;
      margin: 0.5rem 0;
      text-align: right;
      &:hover,
      &:active {
        color: ${props => props.theme.colors.textTitle};
        a:before {
          width: 100%;
          left: 0;
          right: auto;
        }
      }
      a {
        color: ${props => props.theme.colors.textContrast};
        font-size: 16px;
        cursor: pointer;
        display: block;
        &:before {
          content: '';
          width: 0;
          height: 1px;
          background: #fff;
          position: absolute;
          bottom: 0;
          left: auto;
          right: 0;
          transition: all 0.2s linear;
        }
      }
    }
    .menu-language-container {
      display: flex;
      a{
      margin: 0 12px;
        &::before {
          content: none;
        }
      }
    }
    .dropdown-mobile {
        display: flex;
        flex-direction: column;
        a {
          &:before {
          display: none;
        }
        }
        .dropdown-mobile-content {
          width: 100%;
          max-height: 0px;
          height: auto;
          display: flex;
          flex-direction: column;
          position: relative;
          left: 0;
          opacity: 0;
          border-radius: 4px;
          transition: ease 150ms;
          overflow: hidden;
          a {
            font-size: 13px;
            padding: 10px 10px;
            width: 100%;
            text-align: right;
            &:before {
              display: none;
            }
          }
        }
        &:hover,&:focus {
          .dropdown-mobile-content {
            max-height: 500px;
            opacity: 1;
          }
        }
      }
  }
`;

export { MenuShadow, SideDrawerStyle };
