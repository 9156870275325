import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';

const IntegracaoBitrix = (): JSX.Element => {
  const b_containerName = 'integracao';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Play2sell + Bitrix24')}</title>
        <meta
          name='description'
          content='Integração da Plataforma Play2sell com Bitrix.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Play2sell + Bitrix24' />
        <meta
          property='og:description'
          content='Integração da Plataforma Play2sell com Bitrix24.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/integration-bitrix/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T10:55:35-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta property='og:image:width' content='800' />
        <meta property='og:image:height' content='447' />
        <meta property='og:image:alt' content='Play2sell + Bitrix24' />
        <meta property='og:image:type' content='image/png' />

        <meta
          name='twitter:title'
          content='Integração da Plataforma Play2sell com Bitrix24.'
        />
        <meta
          name='twitter:description'
          content='Integração da Plataforma Play2sell com Bitrix24.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <Header
          b_container={b_containerName}
          title={t('Play2sell + Bitrix24')}
          subtitulo={t('Bitrix24')}
        />
        <main className='container-main'>
          <img src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FBitrix.png?alt=media&token=0d7037c3-fe8e-4d41-9a60-7f368e14a96e' />
          <CardText
            b_container={b_containerName}
            b_str='1'
            title={
              t(
                'A Plataforma Play2sell está integrada com as melhores soluções do mercado'
              ) || ''
            }
            text={
              t(
                'Bitrix24 é uma plataforma tudo-em-um para gerenciar equipes e executar vendas, foi projetado para ser integrado a todos os recursos que ele tem como CRM, gerenciador de tarefa,reuniões on-line, gerenciador de clientes, suporte ao cliente, automação de marketing e vendas.'
              ) || ''
            }
          />
          <CardImage
            b_container={b_containerName}
            title=''
            imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2Fcrm.png.webp?alt=media&token=3c273cd7-5c45-4446-934f-ea0f7bc6068f'
            img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2Fcrm.png.webp?alt=media&token=3c273cd7-5c45-4446-934f-ea0f7bc6068f'
          />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title=''
            text={
              t(
                'A plataforma Play2sell gamifica seu processo comercial, unindo capacitação e incentivo por meio de games personalizados, trazendo mais engajamento para sua equipe de vendas. E com a integração do Bitrix24, é possível aumentar a agilidade dos processos de seu negócio e tornar a tomada de decisão muito mais assertiva evitando riscos, desperdícios e retrabalho.'
              ) || ''
            }
          />

          <p style={{ color: 'red' }}>
            <a
              href='https://www.bitrix24.com.br/'
              style={{ color: 'red' }}
              target='_blank'
              rel='noreferrer'>
              {t('Saiba mais sobre a ferramenta Bitrix24!')}
            </a>
          </p>

          <ContatoCaller
            b_container={b_containerName}
            b_str='4'
            title=''
            buttonText={t('Vamos conversar?') || ''}
          />
        </main>
      </main>
      <Footer />
    </>
  );
};
export default IntegracaoBitrix;
