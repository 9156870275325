const node_env = process.env.NODE_ENV;
let URL_API = '';
let HASH = '';
switch (node_env) {
  case 'development':
    // URL_API = 'http://localhost:3333';
    URL_API = 'https://api.staging.p2s-go.com';
    HASH = 'Iiz69I6Nf5GaWLWS8GxH4s1Nv2yaVw98r7Tk6L27WVaoByGSHk';
    break;
  default:
    HASH = 'Iiz69I6Nf5GaWLWS8GxH4s1Nv2yaVw98r7Tk6L27WVaoByGSHk';
    URL_API = 'https://api.p2s-go.com';
    break;
}

export { URL_API, HASH };
