import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import Style from './styles';

const Clientes = (): JSX.Element => {
  const { t } = useTranslation();
  const listLogos = [
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FBeHome%20imobiliaria%20-%20play2sell.png?alt=media&token=4bb706c5-c7e8-4e03-a5e2-cc26e3a4a9ef',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FBuzz%20Inteligencia%20Imobiliaria%20-%20Play2sell.png?alt=media&token=671d12bc-7d0d-49ac-9e49-250a8c76d891',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FFranzolin%20incorporadora%20play2sell.jfif?alt=media&token=0d7e56ad-a45d-4621-9e62-bb5ed22ac9cb',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FLogo_CPFL_Energia%20play2sell.png?alt=media&token=0db9fdf7-835e-432c-9cd2-42a4132f7855',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Felanco%20pet%20play2sell.png?alt=media&token=79e76037-f61f-4976-8e2d-4bfd8040f8b5',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Fextec%20construtora%20play2sell.jpg?alt=media&token=5896ac9f-6d92-4b26-922e-50667e1288af',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Flogo%20alfa%20construtora%20play2sell.png?alt=media&token=2e6842af-83dc-49e6-9b76-0ab46e8e7708',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Flogo%20engelux%20construtora%20incorporadora%20play2sell.png?alt=media&token=f44f2852-cc66-43df-98b8-f0e01541e884',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Fgraal%20engenharia%20logo%20play2sell.png?alt=media&token=00aab7a3-a856-4246-bf4a-1a1f54ff7896',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Flogo%20lote5%20imobiliaria%20play2sell.png?alt=media&token=355a02cb-040d-46e2-b5be-2c9c262ae7b3',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Flogo%20myralis%20farmaceutica%20play2sell.jpg?alt=media&token=1d2913da-40cb-415f-aa83-bcbbe023b12c',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Fremax%20imobiliaria%20play2sell.jpg?alt=media&token=2bc8dfa9-5c37-487b-8070-ee00246143d0',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Funimed%20saude%20uberlandia%20play2sell.png?alt=media&token=eba800a8-1dc1-4f02-b8da-73acc5c0cd2e',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2Fvinx%20construtora%20play2sell.png?alt=media&token=3c2c9783-212b-4b13-8363-fea9349fd605',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FYuny_Logo%20construtora%20play2sell.png?alt=media&token=be035d1e-3805-4eda-9b9e-e84fbe7881a5',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FPatriani%20Construtora%20-%20Play2sell.png?alt=media&token=a2a13147-2904-4c5a-ac3f-e8f214dde910',
    'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FSF%20consultoria%20imobiliaria%20play2sell.png?alt=media&token=dea7b6dd-3372-42e4-92f0-896cbf919ab0'
  ];
  const settings = {
    slidesToShow: 5,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          infinite: true,
          centerMode: true,
          centerPadding: '60px'
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '80px'
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '50px'
        }
      }
    ]
  };
  return (
    <Style>
      <div className='clientes'>
        <h1 b-container={`clientes`} b-str='titulo'>
          {t('Clientes que gamificam suas vendas')}
        </h1>
        <div className='imgs'>
          <Slider {...settings}>
            {listLogos.map((logo: any, idx: number) => (
              <div key={idx}>
                <div className='img'>
                  <img src={logo} alt={`Imagem`} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Style>
  );
};

export default Clientes;
