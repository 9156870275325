import styled from 'styled-components';

const FooterStyle = styled.footer`
  width: 100%;
  position: relative;
  background: linear-gradient(145deg, #e20c18, #e30647);
  font-family: ${props => props.theme.fontFamily.title};
  color: #fff;
  border-top: 1px solid #fff;
  margin-top: 100px;
  .footer-background {
    width: 10%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    left: 0;
    .wave-footer-svg {
      position: absolute;
      top: 0;
      z-index: 0;
      transform: rotate(180deg);
      @media screen and (max-width: 560px) {
        transform: rotate(180deg) scale(0.75);
        left: -770px;
        top: -50px;
      }
    }
    .left-footer-dots-svg {
      position: absolute;
      top: 0;
      left: -25%;
      z-index: 0;
      @media screen and (max-width: 560px) {
        top: -25px;
        left: -675px;
      }
    }
    .right-footer-dots-svg {
      position: absolute;
      bottom: -25px;
      right: -33%;
      z-index: 0;
      @media screen and (max-width: 560px) {
        bottom: -250px;
        right: -125%;
      }
    }
  }
  .footer-container {
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 0 35px;
    .newsletter-container {
      width: 100%;
      background: #f7f9f8;
      border-radius: 25px;
      position: relative;
      display: flex;
      top: 30px;
      justify-content: space-between;
      z-index: 0;
      img {
        position: relative;
        top: 65px;
        left: 25px;
        transform: scale(1.75);
        max-height: 400px;
      }
      .newsletter-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 55%;
        padding: 75px;
        p {
          color: #e20c18;
          font-size: 40px;
          margin-bottom: 35px;
        }
        input {
          font-family: ${props => props.theme.fontFamily.title};
          font-size: 20px;
          width: 100%;
          padding: 20px;
          border-radius: 4px;
          margin-bottom: 20px;
        }
        button {
          font-family: ${props => props.theme.fontFamily.title};
          background: #e20c18;
          color: #fff;
          font-size: 16px;
          transition: ease-in-out 250ms;
          padding: 20px 75px;
          border-radius: 45px;
          margin-top: 30px;
          &:hover,
          &:focus {
            background: #fff;
            color: #e20c18;
          }
        }
      }
    }
    //parte inferior
    .footer-info {
      position: relative;
      padding-top: 120px;
      z-index: 1;
      .footer-middle {
        display: flex;
        margin-bottom: 50px;
        .footer-title {
          position: relative;
          font-size: 1.5rem;
          margin-bottom: 25px;
          &:before {
            content: '';
            position: absolute;
            width: 4px;
            height: 1.5rem;
            top: 3px;
            left: -15px;
            background: white;
          }
        }
        .left-container {
          width: 40%;
          padding-left: 15px;
          .left-info {
            margin-bottom: 40px;
            font-size: 1rem;
            span {
              display: block;
              font-size: 1.125rem;
              margin-bottom: 15px;
            }
          }
        }
        .center-container {
          width: 35%;
          margin-left: 5%;
          padding-left: 15px;
          ul {
            li {
              margin-bottom: 10px;
              a:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .right-container {
          width: 20%;
          padding-left: 15px;
          .btn-integration {
            width: 100%;
            min-width: 215px;
            background: #ffffff40;
            display: flex;
            align-items: center;
            padding: 15px 25px;
            @media screen and (max-width: 1400px) {
              padding: 15px 15px;
            }
            border-radius: 6px;
            margin-bottom: 20px;
            cursor: pointer;
            transition: ease-in-out 200ms;
            b {
              font-size: 1.5em;
            }
            &:hover,
            &:focus {
              background: #ffffff;
              color: #e30647;
            }
          }
          ul li {
            margin-bottom: 10px;
            a:hover,
            a:focus {
              text-decoration: underline;
            }
          }
        }
      }
      .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid white;
        padding-top: 25px;
        padding-bottom: 35px;
        font-size: 18px;
        img {
          width: 170px;
        }
        .eula-conditions {
          display: flex;
          gap: 30px;
          a {
            border-radius: 4px;
          }
          a:hover,
          a:focus {
            color: #e30647;
            background: #fff;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
  @media screen and (min-width: 1200px) {
    .footer-container {
      max-width: 70%;
      padding: 0;
    }
  }
  @media screen and (max-width: 1500px) {
    .footer-container {
      .footer-info {
        .footer-middle {
          .center-container {
            margin-left: 0;
          }
          .right-container {
            width: 25%;
          }
        }
      }
      .newsletter-container {
        img {
          left: -35px;
          transform: scale(1.5);
        }
        .newsletter-form {
          width: 65%;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .footer-container .newsletter-container {
      img {
        left: 0;
        transform: scale(1);
        max-width: 35%;
      }
      .newsletter-form {
        width: 65%;
      }
      .red-circle-footer {
        display: none;
      }
    }
  }
  @media screen and (max-width: 880px) {
    .footer-container {
      .footer-info {
        .footer-middle {
          flex-wrap: wrap;
          .left-container {
            width: 50%;
          }
          .center-container {
            width: 50%;
          }
        }
      }
      .newsletter-container {
        flex-direction: column;
        height: auto;
        z-index: 2;
        img {
          display: none;
        }
        .newsletter-form {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 560px) {
    .footer-container {
      .newsletter-container {
        padding: 15px;
        width: calc(100% + 70px);
        left: -35px;
        top: 60px;
        .newsletter-form {
          padding: 0;
          p {
            font-size: 24px;
            text-align: center;
          }
          input {
            font-size: 16px;
            padding: 15px;
          }
          button {
            margin-top: 15px;
            padding: 10px 25px;
          }
        }
      }
      .footer-info {
        .footer-middle {
          flex-direction: column;
          .left-container {
            width: 100%;
            padding-right: 15px;
            padding-left: 35px;
          }
          .center-container {
            width: 100%;
            padding-right: 15px;
            padding-left: 35px;
            margin: 0;
          }
          .right-container {
            width: 80%;
            padding-left: 35px;
          }
        }
        .footer-bottom {
          flex-direction: column-reverse;
          text-align: center;
          font-size: 16px;
          border-color: #ffffff60;
          img {
            margin-bottom: 20px;
          }
          .eula-conditions {
            display: none;
          }
        }
      }
    }
    .mobile {
      display: block;
    }
  }
`;

export default FooterStyle;
