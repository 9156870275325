import Styled from 'styled-components';

const HeaderIndexAltStyle = Styled.div`
  display: flex;
  position:relative;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  min-height: 90vh;
  width: 100%;
  font-size: 18px;
  color: ${props => props.theme.colors.textContrast};
  font-family: ${props => props.theme.fontFamily.title};
  background: linear-gradient(155deg, #e20c18, #e30647);
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
  .carousel{
    width: 100%;
  }
  .hero-container {
    max-width: 95%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    z-index: 1;
    @media screen and (min-width: 1200px) {
      max-width: 80%;
    }
    .hero-info {
      flex:1;
      .hero-title {
        font-size: 4em;
        margin-bottom: 20px;
        text-align:start;
      }
      .hero-text {
        font-size: 1.25em;
        margin-bottom: 40px;
        text-align:start;
      }
      .hero-buttons {
        display: flex;
        align-items: center;
        .btn-hero {
          display: inline-block;
          padding: 20px 75px;
          border-radius: 30px;
          transition: 200ms ease-in-out;
          background: #fff;
          color: #09206A;
          margin-right: 30px;
          &:hover {
            color: #e20c18;
            box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
          }
        }
        .btn-hero-alt {
          display: inline-block;
          padding: 18px 60px;
          border-radius: 30px;
          transition: 200ms ease-in-out;
          background: #E20C18;
          color: #fff;
          border: 2px solid #fff;
          &:hover {
            background: #fff;
            color: #e20c18;
            box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
          }
        }
      }
    }
    .hero-image {
      flex:1;
    }
  }
  @media screen and (max-width: 1750px) {
    .hero-container {
      .hero-info {
        .hero-title {
          font-size: 48px;
        }
        .hero-text {
          font-size: 24px;
        }
        .btn-hero {
          font-size: 16px;
          padding: 15px 60px;
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .hero-container {
      .hero-info {
        width: 75%;
        .hero-title {
          font-size: 32px;
        }
        .hero-text {
          font-size: 16px;
        }
        .btn-hero {
          font-size: 16px;
          padding: 15px 60px;
        }
      }
      /* .hero-image {
        img {
          width: 65%;
        }
      } */
    }
  }
  @media screen and (max-width: 880px) {
    min-height: 70vh;
    align-items: normal;
    .hero-container {
      padding: 0 35px;
      margin-top: 170px;
      .hero-info {
        width: 100%;
        .hero-title {
          font-size: 30px;
          margin-bottom: 10px;
        }
        .hero-text {
          margin-bottom: 15px;
        }
        .hero-buttons {
        .btn-hero {
          padding: 15px 55px;
          font-size: 10px;
        }
      }
    }
      .hero-image {
        display: none;
      }
    }
  }
  @media screen and (max-width: 560px) {
    .hero-container {
      .hero-info {
        .hero-buttons {
          .btn-hero {
            padding: 15px 30px;
            font-size: 10px;
          }
        }
      }
    }
  }
  @media (max-height: 580px) and (orientation: landscape) {
    padding-bottom: 75px;
    .hero-container {
      margin-top: 135px;
    }
  }
  @media (max-height: 580px) and (orientation: portrait) {
    min-height: 75vh;
    .hero-container {
      margin-top: 135px;
    }
  }
  @media screen and (min-height: 800px) and (orientation: portrait) {
    min-height: 45vh;
  }
`;

export { HeaderIndexAltStyle };
