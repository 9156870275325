import { CaretDownFill } from '@styled-icons/bootstrap/CaretDownFill';
import { Link } from 'react-router-dom';
import i18n from '../../utils/translate';
import { MenuOptions, menuLanguages } from '../headerNav';
import { MenuShadow, SideDrawerStyle } from './styles';

const MenuListItem = (props: any) => {
  return (
    <li key={props.indice}>
      <Link to={props.href}>{props.title}</Link>
    </li>
  );
};

interface SideDrawerProps {
  className: any | null;
}

const SideDrawer = ({ className }: SideDrawerProps) => {
  return (
    <>
      <SideDrawerStyle className={className}>
        <ul>
          {MenuOptions().map((option, index: number) => (
            <li
              key={`${index}_mobile_${option.name}`}
              className='dropdown-mobile'>
              {!option.children ? (
                <Link to={option.link}>
                  {option.name}
                  {option.children ? <CaretDownFill size='16px' /> : null}
                </Link>
              ) : (
                <a>
                  {option.name}
                  {option.children ? <CaretDownFill size='16px' /> : null}
                </a>
              )}
              <div className='dropdown-mobile-content'>
                {option.children?.map((suboption: any, index: number) => (
                  <Link
                    key={`${index}_submenu_mobile_${option.name}`}
                    to={suboption.link}>
                    {suboption.name}
                  </Link>
                ))}
              </div>
            </li>
          ))}
          <MenuListItem
            title={'Login'}
            href={'https://admin.play2sell.com/login'}
          />
          <li className='menu-language-container'>
            {menuLanguages.map((item, index) => (
              <a key={index}>
                <img
                  src={item.icon}
                  alt={item.key.toUpperCase()}
                  width='18px'
                  height='12px'
                  onClick={() => i18n.changeLanguage(item.key)}
                />
              </a>
            ))}
          </li>
        </ul>
      </SideDrawerStyle>
      <MenuShadow className={className} />
    </>
  );
};

export default SideDrawer;
