import styled from 'styled-components';

const Style = styled.section`
  margin: 4em 0;
  background: #ffff;
  width: 100%;
  h2 {
    font-size: 22px;
    font-weight: 600;
    color: #e20b1e;
  }
  p {
    font-size: ${props => props.theme.fontSize.default};
    line-height: 1.7;
    color: #7283ba;
    white-space: pre-wrap;
    margin-top: 1em;
  }
  b {
    color: #e20b1e;
    font-weight: 600;
  }
  a {
    color: #e20b1e;
    font-weight: 600;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1100px) {
    p {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
  }
  @media screen and (max-width: 560px) {
    p {
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
  }
`;

export default Style;
