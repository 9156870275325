import { concat, intersection } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import {
  DataClient,
  DataIntegrations,
  DataInvestors,
  DataNewsLetter,
  DataVacancies,
  InputItem,
  LeadType,
  SelectItem,
  TextAreaItem
} from '../../types/common';
import { setLead, uploadFiles } from '../../utils/services';
import { validateEmail, validateLinkedIn } from '../../utils/utils';
import CheckboxInput from '../checkboxInput';
import FormStyle from './styles';
interface FormProps {
  type: LeadType;
  title: string;
  sm?: boolean;
  subtitle?: string;
  warning?: string;
  uploadButton?: string;
  uploadWarning?: string;
  notificationEmails?: string[];
  button: string;
  inputs?: InputItem[];
  select?: SelectItem[];
  textearea?: TextAreaItem[];
}

const Form = ({
  inputs,
  textearea,
  title,
  sm,
  subtitle,
  warning,
  button,
  select,
  uploadButton,
  uploadWarning,
  type
}: FormProps): JSX.Element => {
  const [arquivo, setArquivo] = useState<any>('');
  const [accepted, setAccepted] = useState(false);
  const [dados, setDados] = useState<any>({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ChangeFileName = async (event: any) => {
    setArquivo(event.target.files[0]);
  };

  const handleValue = (event: any) => {
    setDados({ ...dados, [event.target.name]: event.target.value });
  };

  const savePDF = async () => {
    const resp = await uploadFiles([arquivo]);
    if (!resp || resp.urls?.length <= 0) {
      throw alert(t('Não foi possivel salvar o arquivo em nossa base'));
    }
    return resp.urls[0];
  };

  const save = async () => {
    try {
      if (!accepted) {
        throw alert(t('Você precisa concordar com os termos!'));
      }
      const requireds = concat(
        (inputs || []).filter(x => x.required).map(x => x.name),
        (textearea || []).filter(x => x.required).map(x => x.name),
        (select || []).filter(x => x.required).map(x => x.name)
      );
      if (
        intersection(
          requireds,
          Object.keys(dados).filter(x => !!dados[x])
        ).length !== requireds.length
      ) {
        throw alert(`${t('Preencha os campos com')} *`);
      }
      //Validar inputs
      if (dados.email && !validateEmail(dados.email)) {
        throw alert(t('Email inválido!'));
      }
      if (dados.linkedin && !validateLinkedIn(dados.linkedin)) {
        throw alert(t('Link do LinkedIn inválido!'));
      }
      if (uploadButton && !arquivo) {
        throw alert(t('Selecione o arquivo para enviar'));
      }

      let url = '';
      if (uploadButton) {
        url = await savePDF();
      }
      let data:
        | DataClient
        | DataInvestors
        | DataVacancies
        | DataNewsLetter
        | DataIntegrations
        | any;
      switch (type) {
        case 'client':
          data = {
            name: dados?.name,
            email: dados?.email,
            phone: dados?.telefone,
            company: dados?.empresa,
            office: dados?.job,
            sellers: dados?.qtd_sales,
            how: dados?.how_met,
            objective: dados?.objective,
            message: dados?.message
          } as DataClient;
          break;
        case 'integration':
          data = {
            name: dados?.name,
            email: dados?.email,
            phone: dados?.telefone,
            company: dados?.empresa,
            message: dados?.message
          } as DataIntegrations;
          break;
        case 'vacancies':
          data = {
            name: dados?.name,
            email: dados?.email,
            phone: dados?.telefone,
            message: dados?.message,
            formation: dados?.academics,
            area: dados?.area,
            curriculum: url,
            linkedin: dados?.linkedin
          } as DataVacancies;
          break;
        case 'investors':
          data = {
            name: dados?.name,
            email: dados?.email,
            phone: dados?.telefone,
            message: dados?.message,
            company: dados?.empresa
          } as DataInvestors;
          break;
      }
      setLead(type, data || {})
        .then(() => {
          alert(t('Enviado com sucesso!'));
          navigate('/thanks');
        })
        .catch(() =>
          alert(t('Não foi possivel registrar seu contato no momento.'))
        );
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <FormStyle sm={sm} fileName={arquivo}>
      <div>
        <span className='title'>{title}</span>
        <div className='subtitle'>{subtitle}</div>
        <div className='warning'>{warning}</div>
        <form>
          {inputs?.map(input => {
            return (
              <label
                className='label-input'
                key={input.key}
                style={{ width: input.tamanhoInline }}>
                {input.mask ? (
                  <InputMask
                    className='input'
                    mask={input.mask}
                    onChange={handleValue}
                    value={dados[input.name] || ''}
                    placeholder={input.placeholder}
                    name={input.name}
                    id={input.id}
                  />
                ) : (
                  <input
                    onChange={handleValue}
                    value={dados[input.name] || ''}
                    className='input'
                    placeholder={input.placeholder}
                    type={input.type}
                    name={input.name}
                    id={input.id}
                  />
                )}
              </label>
            );
          })}
          {select &&
            select.map(select => {
              return (
                <label
                  className='label-input'
                  key={select.key}
                  style={{ width: select.tamanhoInline }}>
                  <select
                    onChange={handleValue}
                    className='input'
                    placeholder={select.placeholder}
                    value={dados[select.name]}
                    defaultValue=''
                    name={select.name}
                    id={select.id}>
                    {select.placeholder && (
                      <option disabled value=''>
                        {select.placeholder}
                      </option>
                    )}
                    {select.options.map(op => {
                      return (
                        <option key={op.key} value={op.value}>
                          {op.text}
                        </option>
                      );
                    })}
                  </select>
                </label>
              );
            })}
          {textearea?.map(text => {
            return (
              <label
                className='label-textarea'
                key={text.key}
                style={{ width: text.tamanhoInline }}>
                <textarea
                  onChange={handleValue}
                  name={text.name}
                  id={text.id}
                  rows={10}
                  className='input'
                  placeholder={text.placeholder}
                  value={dados[text.name] || ''}></textarea>
              </label>
            );
          })}
          <div className='btn-container'>
            {uploadButton && (
              <>
                <div className='file-name-advice'>{uploadWarning}</div>
                <label className='btn-file-label'>
                  <div className='btn-file'>{uploadButton}*</div>
                  <div className='file-name'>
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      {arquivo.name}
                    </div>
                  </div>
                  <input
                    type='file'
                    accept='.pdf, .docx'
                    name='file-input'
                    id='file-input'
                    style={{
                      visibility: 'hidden',
                      opacity: 0,
                      width: 0,
                      height: 0,
                      display: 'none'
                    }}
                    onChange={event => ChangeFileName(event)}
                  />
                </label>
              </>
            )}
            <CheckboxInput
              name='terms'
              id='terms'
              text={t(
                'Ao inscrever-se, você concorda com nossos <a href="/terms-of-use" target="_blank" rel="noreferrer">termos de uso</a> e <a href="/privacy-policy" target="_blank" rel="noreferrer">políticas de privacidade</a>. Não se preocupe, nós não enviamos spam.'
              )}
              value={accepted.toString()}
              on_change={() => setAccepted(!accepted)}
            />
            <div className='btn-send' onClick={save}>
              {button}
            </div>
          </div>
        </form>
      </div>
    </FormStyle>
  );
};

export default Form;
