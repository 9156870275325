import Styled from 'styled-components';

const ExperienceContainerStyle = Styled.div`
  width: 100%;
  position: relative;
  background: linear-gradient(135deg, #e20c18, #e30647);
  color: #fff;
  text-align: center;
  padding-top: 55px;
  padding-bottom: 60px;
  overflow: hidden;
  .experience-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    .experience-title {
      font-size: 3.125em;
      max-width: 585px;
      margin-bottom: 10px;
    }
    .experience-subtitle {
      max-width: 740px;
      margin-bottom: 40px;
    }
    .experience-slider-container {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      .img-container {
        max-width: 300px;
        max-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 95%;
          max-width: 95%;
          margin: 0 auto;
        }
      }
    }
  }
  .svg-right {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  .svg-left {
    position: absolute;
    left: -125px;
    top: 0;
    z-index: 0;
  }
  @media screen and (max-width: 1100px) {
    .experience-container .experience-slider-container .img-container {
      max-width: 200px;
    }
  }
  @media screen and (max-width: 800px) {
    border-radius: 10px;
    padding: 55px 30px 30px 30px;
    .experience-container {
      .experience-title {
        font-size: 30px;
        margin-bottom: 15px;
      }
      .experience-slider-container {
        display: block;
        .img-container {
          max-width: 300px;
          height: 300px;
          margin: 0 auto;
          outline: none;
          img {
            max-height: 95%;
            max-width: 95%;
            margin: 0 auto;
          }
        }
      }
    }
    .svg-right {
      right: -85px;
      bottom: -50px;
      transform: scale(0.8);
    }
    .svg-left {
      left: -200px;
      top: -110px;
      transform: scale(0.5);
    }
  }
`;

export default ExperienceContainerStyle;
