import { Apple } from '@styled-icons/boxicons-logos/Apple';
import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { PlayStore } from '@styled-icons/boxicons-logos/PlayStore';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Youtube } from '@styled-icons/boxicons-logos/Youtube';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, ScrollRestoration } from 'react-router-dom';
import { DataNewsLetter } from '../../types/common';
import { setLead } from '../../utils/services';
import { validateEmail } from '../../utils/utils';
import CheckboxInput from '../checkboxInput';
import { RedCircle } from '../svg_list';
import FooterStyle from './styles';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [accepted, setAccept] = useState<boolean>(false);
  const footerData = [
    {
      name: t('Política de privacidade'),
      link: '/privacy-policy'
    },
    {
      name: t('Termos e condições'),
      link: '/terms-of-use'
    },
    {
      name: t('Sobre a empresa'),
      link: '/about'
    },
    {
      name: t('Investidores'),
      link: '/relationship-with-investors'
    },
    {
      name: t('Contato'),
      link: '/contact'
    },
    {
      name: t('Vagas'),
      link: '/work-with-us'
    },

    {
      name: t('Ajuda'),
      link: 'https://help.play2sell.com/pt-BR/'
    },
    {
      name: t('Login'),
      link: 'https://admin.play2sell.com/login'
    }
  ];

  const save = () => {
    try {
      if (!name) {
        throw alert(`${t('Preencha os campos com')} *`);
      }
      if (!email) {
        throw alert(`${t('Preencha os campos com')} *`);
      }
      if (!validateEmail(email)) {
        throw alert(t('Email inválido!'));
      }
      if (!accepted) {
        throw alert(t('Você precisa concordar com os termos!'));
      }
      const data: DataNewsLetter = {
        name,
        email
      };
      setLead('newsletter', data)
        .then(() => {
          alert(t('Inscrição concluída!'));
          setEmail('');
          setName('');
          setAccept(false);
        })
        .catch(() => {
          alert(t('Não foi possivel registrar seu contato no momento.'));
        });
    } catch (error) {
      alert(t('Não foi possivel registrar seu contato no momento.'));
    }
  };
  return (
    <FooterStyle>
      <div className='footer-background'></div>
      <div className='footer-container'>
        <div className='newsletter-container'>
          <RedCircle
            className='red-circle-footer'
            style={{
              position: 'absolute',
              zIndex: 1,
              right: '30px',
              top: '-30px',
              transform: 'scaleX(-1)'
            }}
          />
          <img
            src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Ffooter_image_resized.png?alt=media&token=b68df401-179f-4c3f-927b-8303a7e47eb3'
            alt='Notebook Email'
          />
          <div className='newsletter-form'>
            <p b-container={['footer']} b-str='form_text'>
              {t('Receba nossos conteúdos')}
            </p>
            <input
              onChange={event => setName(event.target.value)}
              placeholder={t('Nome') || ''}
              value={name || ''}
              name='name'
            />
            <input
              onChange={event => setEmail(event.target.value)}
              placeholder={t('Email') || ''}
              value={email || ''}
              name='email'
            />
            <CheckboxInput
              noMargin
              name='terms'
              id='terms'
              text={t(
                'Ao inscrever-se, você concorda com nossos <a href="/terms-of-use" target="_blank" rel="noreferrer">termos de uso</a> e <a href="/privacy-policy" target="_blank" rel="noreferrer">políticas de privacidade</a>. Não se preocupe, nós não enviamos spam.'
              )}
              on_change={() => setAccept(!accepted)}
              value={accepted.toString()}
            />
            <button onClick={save}>
              {t('Assinar Newsletter').toUpperCase()}
            </button>
          </div>
        </div>
        <div className='footer-info'>
          <div className='footer-middle'>
            <div className='left-container'>
              <div
                className='footer-title'
                b-container={['footer']}
                b-str='titulo_esquerda'>
                {t('Como falar com a gente?')}
              </div>
              <div className='left-info'>
                <span b-container={['footer']} b-str='telefone'>
                  {t('Comercial')}
                </span>
                <div b-container={['footer']} b-str='atendimento'>
                  comercial@play2sell.com
                </div>
              </div>
              <div className='left-info'>
                <span b-container={['footer']} b-str='email_1'>
                  {t('Imprensa')}
                </span>
                <div b-container={['footer']} b-str='email_texto_1'>
                  <p>suporte@play2sell.com</p>
                </div>
              </div>
              <div className='left-info'>
                <span b-container={['footer']} b-str='email_2'>
                  {t('Suporte')}
                </span>
                <div b-container={['footer']} b-str='email_texto_2'>
                  suporte@play2sell.com
                </div>
              </div>
            </div>
            <div className='center-container'>
              <div
                className='footer-title'
                b-container={['footer']}
                b-str='titulo_centro'>
                {t('Links extras')}
              </div>
              <ul>
                {footerData.map((link: any, index: any) => (
                  <li key={index}>
                    <Link to={link.link}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='right-container'>
              <div
                className='footer-title'
                b-container={['footer']}
                b-str='titulo_direita'>
                {t('Conheça nosso app')}:
              </div>
              <a
                href='https://play.google.com/store/apps/details?id=com.p2s.go.mobile.meanas&hl=pt_BR'
                target='_blank'
                rel='noreferrer'
                className='btn-integration'>
                <PlayStore size='48px' />
                <div className='integration-box'>
                  {t('Baixar na')}
                  <br />
                  <b>Play Store</b>
                </div>
              </a>
              <a
                href='https://apps.apple.com/br/app/play2sell-go/id1513722044'
                target='_blank'
                rel='noreferrer'
                className='btn-integration'>
                <Apple size='48px' />
                <div className='integration-box'>
                  {t('Baixar na')}
                  <br />
                  <b>Apple Store</b>
                </div>
              </a>
            </div>
          </div>
          <div className='footer-bottom'>
            <span>Copyright 2021. {t('Todos os direitos reservados.')}</span>
            <Link to='/'>
              <img
                src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FPS2_logo.png?alt=media&token=a30df6ab-87c2-42ba-8eab-c1bc9a314d2d'
                alt='Play2sell Logo'
              />
            </Link>
            <div className='eula-conditions'>
              <a
                href='https://www.instagram.com/play2sell/'
                target='_blank'
                rel='noreferrer'>
                <Instagram size='32px' />
              </a>
              <a
                href='https://twitter.com/play2sell/'
                target='_blank'
                rel='noreferrer'>
                <Twitter size='32px' />
              </a>
              <a
                href='https://www.linkedin.com/company/play2sell/'
                target='_blank'
                rel='noreferrer'>
                <Linkedin size='32px' />
              </a>
              <a
                href='https://www.facebook.com/play2sell/'
                target='_blank'
                rel='noreferrer'>
                <Facebook size='32px' />
              </a>
              <a
                href='https://www.youtube.com/channel/UC2iC5JlQF41ItfNEMn6RkSQ'
                target='_blank'
                rel='noreferrer'>
                <Youtube size='32px' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ScrollRestoration />
    </FooterStyle>
  );
};

export default Footer;
