import styled from 'styled-components';

const Style = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px 0;
  gap: 30px;
  .title {
    color: ${props => props.theme.colors.textTitle};
    font-size: 42px;
    text-align: center;
    font-weight: 600;
  }
  .button {
    display: inline-block;
    padding: 20px 45px;
    border-radius: 30px;
    transition: 200ms ease-in-out;
    background: #e20c18;
    color: #fff;
    text-align: center;
    &:hover {
      box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
    }
  }
  @media screen and (max-width: 560px) {
    .title {
      font-size: 30px;
    }
  }
`;

export default Style;
