import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardInfos from '../../components/cardInfos';
import CardNumber from '../../components/cardNumber';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';
import Style from './styles';

const TreinamentoDeVendas = (): JSX.Element => {
  const b_containerName = 'treinamento';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Treinamento de Vendas - Play2sell')}</title>
        <meta
          name='description'
          content='Um Treinamento de Vendas gamificado ajuda sua equipe a ter maior engajamento e melhores resultados de vendas.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Treinamento de Vendas - Play2sell' />
        <meta
          property='og:description'
          content='Um Treinamento de Vendas gamificado ajuda sua equipe a ter maior engajamento e melhores resultados de vendas.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/sales-training/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T10:55:35-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta property='og:image:width' content='800' />
        <meta property='og:image:height' content='447' />
        <meta
          property='og:image:alt'
          content='Treinamento de Vendas - Play2sell'
        />
        <meta property='og:image:type' content='image/png' />
        <meta
          name='twitter:title'
          content='Treinamento de Vendas - Play2sell'
        />
        <meta
          name='twitter:description'
          content='Um Treinamento de Vendas gamificado ajuda sua equipe a ter maior engajamento e melhores resultados de vendas.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_containerName}
        title={t('Treinamento de vendas')}
        subtitulo={t('treinamento de vendas')}
      />
      <Style className='container-main'>
        <ContatoCaller
          b_container={b_containerName}
          b_str='1'
          title={t('Aumente suas vendas com treinamentos gamificados') || ''}
          buttonText={t('Vamos conversar?') || ''}
        />
        <CardImage
          b_container={b_containerName}
          title={t('O que é um treinamento de vendas gamificado?')}
          imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.webp?alt=media&token=5b470a1e-a6d0-49c1-8923-8dbfd39999d4'
          img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <CardText
          b_container={b_containerName}
          b_str='1'
          title=''
          text={`${t(
            'Um treinamento de vendas gamificado é uma forma de capacitar a sua equipe comercial por meio de games, ou seja, com o auxílio de jogos, sejam analógicos e/ou digitais. É um método de ensino focado tanto no preparo dos profissionais quanto no aumento da motivação deles.\n\nO objetivo é apresentar aos participantes as melhores práticas que envolvem todo o processo da venda, para que a equipe possa aprender a executar suas atividades de forma rápida e eficaz. Este é um excelente método de fazer com que a conversão em vendas e a motivação dos colaboradores aumentem.\n\nA proposta do treinamento de vendas gamificado é adotar a lógica, as regras e o design de jogos, com o objetivo de preparar os profissionais de uma forma mais lúdica, dinâmica e divertida.\n\nEste formato de treinamento é bastante recomendado nos dias atuais por unir o aprendizado à diversão, o que gera maior engajamento entre os participantes, faz com que os colaboradores estejam mais motivados e ajuda a reter os conhecimentos que eles receberão.\n\nFalando nisso, estudos apontam que os participantes de treinamentos de vendas gamificados, em média, lembram de 60% de toda a informação apresentada. Ou seja, esta é uma forma bastante eficiente de aprendizado.\n\nCom a evolução da tecnologia e o crescimento do uso de smartphones, os treinamentos de vendas gamificados ficaram ainda mais fáceis de serem utilizados, com a facilidade dos usuários poderem acessar em qualquer horário e local.\n\nEsta modalidade de treinamento pode ser útil para atender três tipos de objetivos'
          )}:`}
          list={[
            t('Mudar comportamentos;'),
            t('Desenvolver habilidades;'),
            t('Capacitar a inovação.')
          ]}
        />
        <CardText
          b_container={b_containerName}
          b_str='2'
          text={
            t(
              'Desta forma, investir neste formato de capacitação pode trazer grandes benefícios para a sua equipe e, assim, sua empresa só tem a ganhar.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='3'
          title={t('Por que treinar uma equipe de vendas?') || ''}
          text={
            t(
              'Atualmente vivemos a chamada Era do Consumidor, ou seja, os clientes estão mais exigentes e muito bem informados sobre os produtos e serviços que desejam. Desta forma, ter uma equipe bem preparada é essencial para conquistar e fidelizar os clientes.\n\nNão basta ter uma equipe de qualidade, os vendedores precisam se adequar às novas tendências, à evolução do mercado e às necessidades de cada perfil de cliente. Além disso, mesmo que já trabalhe na área ou na empresa há bastante tempo, o profissional precisa se atualizar com certa frequência para não ficar preso aos métodos do passado.\nÉ importante trazer nos treinamentos novas formas de abordagem e técnicas de vendas, o que fará com que os colaboradores estejam confiantes na hora de atender seus clientes e alcancem o sucesso.\n\nConhecer os produtos e serviços que a empresa oferece, entender as melhores abordagens, aprender a utilizar as ferramentas utilizadas pela área, saber como tratar o cliente, entre outros assuntos também podem ser bem explicados durante um treinamento de vendas.\n\nIsso irá elevar o nível de satisfação dos profissionais com relação à empresa, já que estes receberão aprendizados importantes para o desenvolvimento de suas carreiras e terão a oportunidade de crescer na profissão.\n\nSendo assim, o treinamento surge como ideal para quem quer ter uma equipe bem capacitada, motivada e preparada para atender os consumidores da forma mais adequada e, o melhor, que entregue melhores resultados.\n\nPor fim, uma equipe comercial bem treinada irá vender ainda mais. Com o treinamento seus profissionais poderão desenvolver habilidades e competências para gerar mais renda para a sua empresa.'
            ) || ''
          }
        />
        <CardText
          b_container={b_containerName}
          b_str='4'
          title={t('Como montar um treinamento de vendas?') || ''}
          text={`${t(
            'Para oferecer uma capacitação eficaz e assertiva, você precisa estabelecer objetivos bem definidos e claros, além de ter um bom planejamento para que as ações sejam certeiras.\n\nSendo assim, você deve responder às seguintes perguntas antes de montar o treinamento da sua equipe comercial'
          )}:`}
        />
        <CardNumber
          b_container={b_containerName}
          b_str='1'
          title={t('Quais são as necessidades da empresa e/ou da equipe?')}
          text={t(
            'É importante fazer um levantamento das necessidades que a empresa e a equipe possuem, para definir quais serão os conteúdos a serem apresentados no treinamento de vendas e para que todos entendam o que é necessário melhorar.'
          )}
        />
        <CardNumber
          b_container={b_containerName}
          b_str='2'
          title={t('Qual será o objetivo?')}
          text={t(
            'A partir desse levantamento das necessidades, estabeleça os principais objetivos a serem alcançados com o treinamento de vendas. Pode ser apresentar um novo produto, capacitar os vendedores, inserir novas regras ou metas da empresa entre os colaboradores, entre outros. O importante é que todos saibam onde, quando e como querem chegar.'
          )}
        />
        <CardNumber
          b_container={b_containerName}
          b_str='3'
          title={t('Qual será o formato desse treinamento?')}
          text={t(
            'Definir como será o treinamento é algo a ser bem discutido. Alguns formatos podem não se encaixar, dependendo do perfil da sua equipe. Treinamentos gamificados, por exemplo, estão em alta hoje em dia, já que unem o aprendizado à diversão, o que ajuda bastante a reter os conhecimentos adquiridos.'
          )}
        />
        <CardNumber
          b_container={b_containerName}
          b_str='4'
          title={t('Como e quando os resultados serão mensurados?')}
          text={t(
            'Todos esperam que o treinamento seja um sucesso, não é certo? Mas saber disso vai muito além do que ver seus colaboradores satisfeitos. É necessário mensurar os resultados a partir dessa capacitação. Definir antes quais serão os indicadores a serem analisados será mais fácil para você e sua equipe.'
          )}
        />
        <CardText
          b_container={b_containerName}
          b_str='5'
          title={t('Quais os principais tipos de treinamento de vendas?') || ''}
          text={`${t(
            'Existem diferentes tipos de treinamentos de vendas, cada um com seus formatos e metodologias diferentes. neste tópico citaremos os mais utilizados pelas empresas hoje em dia'
          )}:`}
        />
        <CardInfos
          b_container={b_containerName}
          position={1}
          name={t('Treinamento operacional')}
          text={t(
            'Este treinamento tem como objetivo principal apresentar aos vendedores todas as ferramentas e os processos utilizados pela empresa e/ou pela área comercial. Cada empresa tem uma forma de trabalhar, tem a sua cultura e seus métodos, portanto é importante apresentá-los aos novos colaboradores. Durante o treinamento o vendedor será capacitado para que possa exercer suas funções da forma mais adequada e da melhor maneira possível.'
          )}
        />
        <CardInfos
          b_container={b_containerName}
          position={2}
          name={t('Treinamento sobre produtos e serviços')}
          text={t(
            'O intuito deste treinamento é fazer com que o profissional conheça a fundo os produtos e serviços que a empresa oferece, para que o vendedor tenha mais segurança na hora de falar com os clientes. Hoje em dia é fundamental que o vendedor saiba tudo sobre os produtos que serviços que a empresa vende, visto que os consumidores de hoje costumam estar bem informados sobre o que procuram. Além disso, o profissional de vendas ganhará mais argumentos para persuadir os clientes.'
          )}
        />
        <CardInfos
          b_container={b_containerName}
          position={3}
          name={t('Treinamento sobre abordagem')}
          text={t(
            'Cada cliente tem um perfil diferente e, para atender a cada um deles, é necessário usar uma abordagem específica. E é sobre isso que esta capacitação trata. Com um atendimento adequado, que responda ao perfil do cliente e que cumpra com suas expectativas, é mais fácil garantir a fidelização dele e obter novos negócios com este consumidor.'
          )}
        />
        <CardInfos
          b_container={b_containerName}
          position={4}
          name={t('Treinamento motivacional')}
          text={t(
            'Sabe aquela “chacoalhada” que às vezes é necessário fazer para animar uma equipe? Pois é, mesmo que seu time de vendas seja muito bom, às vezes é necessário motivá-los para que continuem a exercer um bom trabalho. Por meio de dinâmicas e cenários do dia a dia, este treinamento de vendas busca uma integração da equipe e aumentar o engajamento dela com relação à empresa. Para isso, utiliza-se métodos que mexem com o emocional dos participantes. Dentro desses tipos de treinamento existem também suas modalidades, sejam elas in company, online ou via gamificação. Independente de qual for escolhida, é essencial que sua empresa invista no treinamento da equipe para obter melhores resultados e aumentar as conversões em vendas.'
          )}
        />
        <ContatoCaller
          b_container={b_containerName}
          b_str='2'
          title={
            t('Fuja do óbvio, invista em treinamentos de vendas gamificados') ||
            ''
          }
          buttonText={t('Agende Uma Reunião') || ''}
        />
      </Style>
      <Footer />
    </>
  );
};

export default TreinamentoDeVendas;
