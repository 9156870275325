import { useTranslation } from 'react-i18next';
import { CoinIcon, LightIcon, PaperIcon } from '../svg_icons';
import { CloudyLeftSVG } from '../svg_list';
import CardRowContainerStyle from './styles';

const CardRowsContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const RowCard = (props: any) => {
    return (
      <div className='list-card'>
        <div className='card-image'>{props.svg}</div>
        <div className='card-info'>
          <div
            className='card-title'
            b-container={`container_info_2`}
            b-str={props.cardTitulo}>
            {props.title}
          </div>
          <div
            className='card-text'
            b-container={`container_info_2`}
            b-str={props.cardText}>
            {props.text}
          </div>
        </div>
      </div>
    );
  };

  return (
    <CardRowContainerStyle>
      <div className='svg-container'>
        <CloudyLeftSVG
          style={{
            position: 'absolute',
            left: '-70%',
            bottom: '-10%',
            zIndex: -1
          }}
        />
      </div>
      <div className='column-left'>
        <picture>
          <source
            srcSet={
              'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fdesktop_img.webp?alt=media&token=0bee3f5d-7d69-45da-9f11-77b13366eb62'
            }
            type='image/webp'
          />
          <source
            srcSet={
              'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fdesktop_img%20(1).png?alt=media&token=8863f216-436e-4b3f-8c57-90bfcb90f6a9'
            }
            type='image/png'
          />
          <img
            src={
              'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fdesktop_img%20(1).png?alt=media&token=8863f216-436e-4b3f-8c57-90bfcb90f6a9'
            }
            b-container={`container_info_2`}
            b-img='img-right'
            alt='Computador'
          />
        </picture>
      </div>
      <div className='column-right'>
        <h2 b-container={`container_info_2`} b-str='titulo'>
          {t('Aumente as vendas e o engajamento do seu time!')}
        </h2>
        <div className='list-card-containers'>
          <RowCard
            svg={<PaperIcon />}
            title={t('Maior conversão de vendas')}
            text={t(
              'Vendedores que dominam todas as informações do produto têm maiores chances de realizar uma venda! Melhores treinamentos geram vendedores mais qualificados que, por sua vez, geram clientes mais satisfeitos.'
            )}
            cardTitulo='card_titulo_1'
            cardText='card_texto_1'
          />
          <RowCard
            svg={<LightIcon />}
            title={t('Equipe mais engajada')}
            text={t(
              'A gamificação é a grande tendência na educação. Os treinamentos tradicionais são entediantes e pouco efetivos.'
            )}
            cardTitulo='card_titulo_2'
            cardText='card_texto_2'
          />
          <RowCard
            svg={<CoinIcon />}
            title={t('Maior retenção de talentos')}
            text={t(
              'Com os vendedores mais motivados e maior volume de vendas, haverá menos turnover e os vendedores mais talentosos permanecerão na empresa!'
            )}
            cardTitulo='card_titulo_3'
            cardText='card_texto_3'
          />
        </div>
      </div>
    </CardRowContainerStyle>
  );
};

export default CardRowsContainer;
