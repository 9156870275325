import parse from 'html-react-parser';
import Style from './styles';

interface CardInfosProps {
  b_container: string;
  position: number;
  name: string;
  text: string;
}

const CardInfos = ({
  position,
  name,
  text,
  b_container
}: CardInfosProps): JSX.Element => {
  return (
    <Style>
      <div className='container'>
        <span className='img'>{position}</span>
        <span className='dados'>
          <p
            b-container={`${b_container}`}
            b-str={`titulo_item_${position}`}
            className='title'>
            {parse(name)}
          </p>
          <p
            b-container={`${b_container}`}
            b-str={`texto_item_${position}`}
            className='function'>
            {parse(text)}
          </p>
        </span>
      </div>
    </Style>
  );
};
export default CardInfos;
