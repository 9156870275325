import { useState } from 'react';
import i18n from '../../utils/translate';
import { PlayIcon } from '../svg_icons';
import VideoContainerStyle from './styles';

interface VideoCardProps {
  url: string;
}
const VideoCard = ({ url }: VideoCardProps) => {
  const [video, setVideo] = useState(false);
  return (
    <div className='gradient-bg'>
      <div className='video-card-container'>
        <div onClick={() => setVideo(true)} className='video-iframe'>
          {video ? (
            <>
              <iframe
                width='100%'
                height='100%'
                src={url || `${url}?autoplay=1`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </>
          ) : (
            <picture onClick={() => setVideo(true)}>
              <PlayIcon
                onClick={() => setVideo(true)}
                style={{ position: 'absolute' }}
              />
              <source srcSet={getUrl().thumbnail} type='image/jpeg' />
              <img src={getUrl().thumbnail} alt='Background Home' />
            </picture>
          )}
        </div>
      </div>
    </div>
  );
};

const getUrl = (): { video: string; thumbnail: string } => {
  switch (i18n.language) {
    // case 'pt':
    // 	return {
    // 		video: 'https://www.youtube.com/embed/H5O0eRy2MqQ',
    // 		thumbnail: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FCaptura%20de%20tela%202024-01-16%20073822.png?alt=media&token=1cf01b19-8292-4e59-9a51-1ac3522aa430'
    // 	};
    // case 'es':
    // 	return {
    // 		video: 'https://www.youtube.com/embed/3VPfeg-MkNI',
    // 		thumbnail: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/imagens_site%2Fcapa-video-welcome.jpg?alt=media&token=e09a747e-c5ae-46b6-b0da-2115e89d6185.jpg'
    // 	};
    default:
      return {
        video: 'https://www.youtube.com/embed/H5O0eRy2MqQ',
        thumbnail:
          'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FCaptura%20de%20tela%202024-01-16%20073822.png?alt=media&token=1cf01b19-8292-4e59-9a51-1ac3522aa430'
      };
  }
};

const VideoContainer = (): JSX.Element => {
  return (
    <VideoContainerStyle>
      <div className='video-container'>
        <VideoCard url={getUrl().video} />
      </div>
    </VideoContainerStyle>
  );
};

export default VideoContainer;
