import styled from 'styled-components';

const topovideo = styled.div`
  max-width: 100%;
  margin: 115px auto;
  @media screen and (min-width: 1200px) {
    max-width: 75%;
  }

  font-family: ${props => props.theme.fontFamily.title};
  .topovideo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 38px;
      font-weight: 100;
      color: #e20c18;
      text-align: center;
    }
    h4 {
      font-size: 18px;
      font-weight: 400;
      color: #7283ba;
      text-align: center;
      margin-top: 16px;
    }
    .img {
      width: 173px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 210px;
        max-height: 230px;
      }
    }
  }
  @media screen and (max-width: 880px) {
    .topovideo {
      h1 {
        font-size: 30px;
        font-weight: 100;
        color: #e20c18;
        text-align: center;
      }
      h4 {
        font-size: 18;
      }
      width: '100%';
      height: '100%';
    }
  }
`;
export default topovideo;
