import { useTranslation } from 'react-i18next';
import Style from './styles';

const TopoVideo = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Style>
      <div className='topovideo'>
        <h1 b-container={`topovideo`} b-str='titulo'>
          {t('Conheça a plataforma gamificada de vendas Play2sell!')}
        </h1>
      </div>
    </Style>
  );
};
export default TopoVideo;
