import styled from 'styled-components';

interface FormStyleProps {
  sm: any;
  fileName?: string;
}

const FormStyle = styled.div<FormStyleProps>`
  font-family: ${props => props.theme.fontFamily.title};
  background: #ffff;
  max-width: 100%;
  margin: 4em 0;
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px;
    margin-top: 20px;
    gap: 2%;
    span.title {
      padding-bottom: 0px;
      font-size: 40px;
      color: ${props => props.theme.colors.textTitle};
      padding: 0px 19px;
    }
  }
  .label-input {
    width: 49%;
  }
  .label-textarea {
    width: 100%;
  }
  .input {
    background: #f5f5f5;
    box-shadow: 0px 3px 6px #00000015;
    padding: 20px;
    border-radius: 7px;
    margin-top: 15px;
    font-family: ${props => props.theme.fontFamily.title};
    border: none;
    width: 100%;
    font-size: 16px;
    &:focus {
      background: #fff;
      padding: 19px;
      border: 1px solid #e20c18;
    }
  }
  textarea {
    font-size: 16px;
    margin-top: 12px;
    border: 0;
    outline: none;
    width: 100%;
  }
  .title {
    color: ${props => props.theme.colors.textTitle};
    font-size: ${props => (props.sm ? '30px' : '40px')};
    width: 100%;
    font-weight: 600;
  }
  .subtitle {
    font-size: ${props => props.theme.fontSize.default};
    color: #7283ba;
    margin-bottom: 20px;
  }
  .warning {
    color: #e20c18;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .btn-send {
    background: #e20c18;
    margin-top: 27px;
    font-size: 15px;
    padding: 20px 52px;
    color: #fff;
    font-weight: 600;
    border-radius: 32px;
    transition: ease 200ms;
    font-family: ${props => props.theme.fontFamily.title};
    cursor: pointer;
    &:hover,
    &:focus {
      filter: brightness(1.1);
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .file-name-advice {
      margin-top: 15px;
    }
  }
  .btn-file-label {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .btn-file {
      background: #57606f;
      font-size: 15px;
      padding: 10px 20px;
      color: #fff;
      font-weight: 600;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      text-align: center;
      &:hover,
      &:focus {
        filter: brightness(1.1);
      }
    }
    .file-name {
      border: 1px solid #ccc;
      padding-left: 5px;
      padding-right: 20px;
      height: 38px;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      position: relative;
      &::after {
        content: '...';
        position: absolute;
        right: ${(props: any) =>
          props?.fileName?.length > 22 ? '5px' : '-15px'};
        bottom: 5px;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .subtitle {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
  }
  @media screen and (max-width: 560px) {
    .subtitle {
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
    .label-input {
      width: 100%;
    }
    .btn-container {
      width: 100%;
    }
    .btn-file-label {
      width: 100%;
      .btn-file {
        min-width: 155px;
        padding: 10px 8px;
      }
      .file-name {
        width: 100%;
      }
    }
  }
`;

export default FormStyle;
