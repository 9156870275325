import styled from 'styled-components';
const VideoContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0%;
  .video-container {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .video-card-container {
    padding: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 210px) {
      width: 200px;
      height: 120px;
    }
    @media screen and (min-width: 260px) {
      width: 240px;
      height: 140px;
    }
    @media screen and (min-width: 400px) {
      width: 340px;
      height: 200px;
    }
    @media screen and (min-width: 600px) {
      width: 426px;
      height: 240px;
    }
    @media screen and (min-width: 800px) {
      width: 640px;
      height: 360px;
    }

    .video-iframe {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      z-index: 1px;
      position: relative;
      background: #1a1a1a;

      iframe {
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        border: 0px solid white;
        border-radius: 23px;
        box-shadow: -10px 85px 35px #4879ff15;
      }

      picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        position: relative;
      }
      svg {
        width: 50%;
        height: 50%;
        circle,
        path {
          transition: fill 0.2s ease;
        }
        &:hover {
          circle {
            fill: #e30647;
          }
          path {
            fill: #fff;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        border: 10px solid white;
        border-radius: 23px;
        box-shadow: -10px -7px 35px #4879ff15;
        object-fit: cover;
      }
    }
  }

  .background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    bottom: 0%;
    top: 0%;
    align-items: center;
    justify-content: center;
  }

  .gradient-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #e20c18, #e30647);
    top: 0;
    padding: 2%;
  }
  .video-slider {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      margin: 0;
    }
  }
  @media screen and (max-width: 210px) {
    display: none;
  }
`;

export default VideoContainerStyle;
