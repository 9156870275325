import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardInfos from '../../components/cardInfos';
import CardText from '../../components/cardText';
import CardTextRed from '../../components/cardTextRed';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import { Box, Game } from '../../components/svg_list';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';
import GamificacaoStyle from './styles';

const Gamificacao = (): JSX.Element => {
  const b_containerName = 'gamificacao';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Gamificação - Play2sell')}</title>
        <meta
          name='description'
          content='Você pode engajar sua equipe e vender mais por meio da gamificação. Saiba mais.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Gamificação - Play2sell' />
        <meta
          property='og:description'
          content='Você pode engajar sua equipe e vender mais por meio da gamificação. Saiba mais.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/o-que-e-gamificacao/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T12:29:51-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fimagem_gamifica%C3%A7%C3%A3o_rsz.jpg?alt=media&token=f4c10558-7c44-4423-b306-2478a3e6fdad'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fimagem_gamifica%C3%A7%C3%A3o_rsz.jpg?alt=media&token=f4c10558-7c44-4423-b306-2478a3e6fdad'
        />
        <meta property='og:image:width' content='583' />
        <meta property='og:image:height' content='386' />
        <meta
          property='og:image:alt'
          content='Gamificacao Play2sell - Gamificação para Vendas'
        />
        <meta property='og:image:type' content='image/jpeg' />

        <meta name='twitter:title' content='Gamificação - Play2sell' />
        <meta
          name='twitter:description'
          content='Você pode engajar sua equipe e vender mais por meio da gamificação. Saiba mais.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fimagem_gamifica%C3%A7%C3%A3o_rsz.jpg?alt=media&token=f4c10558-7c44-4423-b306-2478a3e6fdad'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <Header
          b_container={b_containerName}
          title={t('Gamificação')}
          subtitulo={t('Gamificação')}
        />
        <GamificacaoStyle>
          <ContatoCaller
            b_container={b_containerName}
            b_str='1'
            title={
              t(
                'Quer saber como a gamificação pode melhorar as suas vendas?'
              ) || ''
            }
            buttonText={t('Agende Uma Reunião') || ''}
          />
          <CardImage
            b_container={b_containerName}
            title={t('O que é gamificação?')}
            imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fimagem_gamifica%C3%A7%C3%A3o_rsz.webp?alt=media&token=026d5562-003e-4736-944e-905bae6c87cb'
            img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fimagem_gamifica%C3%A7%C3%A3o_rsz.jpg?alt=media&token=f4c10558-7c44-4423-b306-2478a3e6fdad'
          />
          <CardText
            b_container={b_containerName}
            b_str='1'
            title=''
            text={
              t(
                'Este termo trata sobre a utilização das mecânicas e recursos de jogo para motivar, engajar e promover a aprendizagem das pessoas em situações reais. Basicamente, é transformar uma situação em jogo, com o objetivo de tornar o aprendizado mais divertido.\n\nEssa metodologia traz o diferencial de despertar um maior engajamento do público. Por esse motivo, a gamificação está cada vez mais presente nos treinamentos corporativos e na fidelização dos colaboradores das empresas.\n\nManter o interesse dos colaboradores e trazer resultados concretos não é uma tarefa fácil. Assim, a gamificação surge como uma alternativa interessante para quem busca aperfeiçoar os métodos de ensino, motivar os profissionais e fazer com que estes retenham as informações.\n\nE os números mostram o quanto este formato é eficiente: uma pesquisa realizada pela TalentLMS aponta que 83% dos trabalhadores se disseram mais motivados com treinamentos gamificados, em comparação com os treinamentos tradicionais. Por outro lado, para aqueles que se sentem entediados, o número caiu de 49% para apenas 10%.\n\nEste formato também utiliza vários elementos de jogos, como sistemas de pontuação e ranking, o que estimula a competição entre os participantes – os quais podem receber recompensas ao alcançar um objetivo. Desta forma, o modelo atrai a atenção das pessoas e promove o aprendizado de forma divertida.'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='2'
            title={t('Quais os tipos de gamificação?') || ''}
            text={
              t(
                'Existem dois modelos para serem utilizados em diferentes contextos ou por diferentes necessidades. É importante saber que os formatos são diferentes, mas que são muito eficazes. Porém, é necessário entender quais os objetivos a serem atingidos antes de escolher um deles ou, até mesmo, mesclar ambos os tipos'
              ) + ':'
            }
          />
          <section className='infos-card'>
            <div className='card-info'>
              <div className='header'>
                <span className='icon'>
                  <Box />
                </span>
                <span b-container={b_containerName} b-str='card_1_titulo'>
                  {t('Analógica')}
                </span>
              </div>
              <div
                className='text'
                b-container={b_containerName}
                b-str='card_1_texto'>
                {t(
                  'É a utilização de tabuleiros ou jogos de cartas, atividades muito utilizadas no passado, mas que ainda assim podem ser bem divertidas. Por meio de baralhos, jogos de tabuleiros ou até mesmo dinâmicas presenciais, é possível criar atividades que irão gerar melhores resultados. No caso não seria apenas um entretenimento, mas também uma ação voltada a trazer impactos positivos, como maior interação, colaboratividade e espírito de equipe.\n\nPor meio de baralhos, jogos de tabuleiros ou até mesmo dinâmicas presenciais, é possível criar atividades que irão gerar melhores resultados. No caso não seria apenas um entretenimento, mas também uma ação voltada a trazer impactos positivos, como maior interação, colaboratividade e espírito de equipe.\n\nA vantagem dessa modalidade é que pode ser utilizada em qualquer local, mesmo sem internet ou sem energia. Apesar de ser considerado pelo público mais jovem como algo ultrapassado, este formato ajuda bastante no aprendizado.'
                )}
              </div>
            </div>
            <div className='card-info'>
              <div className='header'>
                <span className='icon'>
                  <Game />
                </span>
                <span b-container={b_containerName} b-str='card_2_titulo'>
                  {t('Digital')}
                </span>
              </div>
              <div
                className='text'
                b-container={b_containerName}
                b-str='card_2_texto'>
                {t(
                  'Já este tipo é o mais usado e procurado atualmente. Como vivemos na era digital, este formato é bastante popular por utilizar a tecnologia – celulares, tablets, computadores.\n\nEste modelo tem a tecnologia como grande aliada para envolver e engajar os participantes. Geralmente realizada em plataformas online, o formato digital traz estatísticas, rankings e resultados em tempo real, o que traz mais dinamismo às competições.\n\nAlém disso, mesmo para as gerações menos acostumadas aos avanços da tecnologia, este tipo é cada vez mais fácil de utilizar, com formatos bem amigáveis aos usuários, como instruções e navegabilidade mais claras.\n\nEste formato também carrega a possibilidade de ser utilizado em qualquer hora ou local, como por exemplo, em casa durante o final de semana, o que aumenta o engajamento dos participantes, independente do ritmo e do tempo que cada um possui no dia a dia.'
                )}
              </div>
            </div>
            <div className='beneficios'>
              <CardText
                b_container={b_containerName}
                b_str='3'
                title={t('Quais os benefícios da gamificação?') || ''}
                text={
                  t(
                    'São muitos os benefícios que a empresa terá ao investir em treinamentos gamificados, por isso escolhemos alguns para descrever mais detalhadamente'
                  ) + ':'
                }
              />

              <div className='opcoes'>
                <CardInfos
                  b_container={b_containerName}
                  position={1}
                  name={t('Engajamento')}
                  text={t(
                    'Através da gamificação, o engajamento dos colaboradores aumenta bastante, já que se trata de uma atividade dinâmica, há um estímulo por práticas competitivas e também colaborativas, como pontuações, troféus e quadro de lideranças. Além disso, para deixar ainda melhor, podem ser desenvolvidos incentivos individuais ou coletivos.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={2}
                  name={t('Gestão de tempo')}
                  text={t(
                    'Com prazos específicos para concluir um determinado trabalho, as pessoas buscam fazer o seu melhor no tempo estimado para que, ao aplicar em seu dia a dia tais aprendizados, consigam resultados significativos na gestão de tempo e execução de uma tarefa ou função.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={3}
                  name={t('Motivação')}
                  text={t(
                    'Por meio das técnicas de gamificação, é possível manter os funcionários focados para atingir metas em troca de recompensas e reconhecimento. O próprio jogador, ao enxergar seu progresso, se sente motivado e busca sempre mais.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={4}
                  name={t('Competição saudável')}
                  text={t(
                    'Por ser um jogo, pode haver uma competição saudável entre os participantes, o que gera maior interatividade e engajamento entre os usuários e a empresa, alé de melhorar o clima organizacional do setor.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={5}
                  name={t('Errar e aprender')}
                  text={t(
                    'A gamificação corporativa proporciona um ambiente de aprendizado seguro, que permite ao jogador aprender com seus erros. Por isso, é capaz de transferir o medo do fracasso para uma experiência de aprendizado divertida e envolvente.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={6}
                  name={t('Trabalho em equipe')}
                  text={t(
                    'O jogo é uma ótima forma para induzir o trabalho em equipe, com desafios que envolvam a necessidade de resultados, para que todos os colaboradores joguem e se ajudem, a fim de alcançar um objetivo — seja ele cumprir um desafio, atingir a pontuação máxima entre as equipes ou até finalizar uma demanda.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={7}
                  name={t('Maior produtividade')}
                  text={t(
                    'Os jogadores recebem atividades específicas e orientadas para determinados objetivos. Isso contribui no aumento da produtividade, pois, o colaborador aplica o que aprendeu em seu dia a dia.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={8}
                  name={t('Desenvolvimento pessoal')}
                  text={t(
                    'Um dos grandes benefícios é poder oferecer aos funcionários oportunidades de aprender, crescer e explorar. Quanto estão jogando, eles percebem que suas capacidades e talentos ocultos surgem com formas eficientes para resolver desafios e problemas.'
                  )}
                />
                <CardInfos
                  b_container={b_containerName}
                  position={9}
                  name={t('Feedback')}
                  text={t(
                    'O feedback pode ser considerado o benefício mais importante, já que a partir dele o participante poderá saber onde e quanto acertou e errou e em quais pontos deve melhorar e evoluir em suas ações para conquistar o objetivo. Sem contar que o feedback colabora para o maior aproveitamento do sistema e para o aumento dos níveis de engajamento'
                  )}
                />
                <CardText
                  b_container={b_containerName}
                  b_str='4'
                  title={
                    t('Como aplicar a gamificação na minha empresa?') || ''
                  }
                  text={
                    t(
                      'Para aplicar na sua empresa de forma assertiva e obter melhores resultados, você precisa se atentar a alguns detalhes'
                    ) + ':'
                  }
                  marginBottom='0'
                />
              </div>
            </div>
          </section>
          <CardTextRed
            b_container={b_containerName}
            b_str='1'
            title={t('Definir objetivos')}
            text={t(
              'É importante estabelecer os objetivos que sua empresa quer alcançar, seja treinar a equipe para novas práticas ou apresentar novos produtos, por exemplo. Dessa forma, os participantes entenderão aonde todos querem chegar.'
            )}
          />
          <CardTextRed
            b_container={b_containerName}
            b_str='2'
            title={t('Pontuações e recompensas')}
            text={t(
              'Incentivar uma competição saudável entre os colaboradores é uma forma interessante de motivar e engajar a equipe. Pode haver um ranking, por exemplo, no qual o vencedor recebe um prêmio, o que irá estimular a participação de todos.'
            )}
          />
          <CardTextRed
            b_container={b_containerName}
            b_str='3'
            title={t('Regras pré-estabelecidas')}
            text={t(
              'Para evitar conflitos e reclamações, estabeleça regras para as competições e deixe-as bem claras para todos os participantes. Além disso, evite mudá-las no decorrer do game, para que ninguém se sinta injustiçado. Isso ajudará você a se prevenir de problemas futuros. Ao mesmo tempo, pode ser interessante trazer novas regras ao final de cada jogo, para aumentar a competitividade entre os usuários.'
            )}
          />
          <CardTextRed
            b_container={b_containerName}
            b_str='4'
            title={t('Tecnologia adequada às necessidades')}
            text={t(
              'As plataformas devem contar com bons recursos da tecnologia para atender as necessidades da empresa. Existem diversas plataformas, as quais oferecem variadas funcionalidades para as empresas e os usuários.\n\nO treinamento gamificado da <a href="/about" target="_blank" rel="noreferrer">Play2sell</a>, por exemplo, conta com ranking e pontuação em tempo real, o que ajuda nas competições, ao serem anunciados os vencedores de cada uma delas, e também oferece um rápido feedback a cada um dos participantes.\n\nAliás, pense também sobre como fará para facilitar o acesso das pessoas que não costumam utilizar a tecnologia com frequência.'
            )}
          />
          <ContatoCaller
            b_container={b_containerName}
            b_str='2'
            title={
              t('Invista na gamificação dos treinamentos para a sua empresa') ||
              ''
            }
            buttonText={t('Agende Uma Reunião') || ''}
          />
        </GamificacaoStyle>
      </main>
      <Footer />
    </>
  );
};

export default Gamificacao;
