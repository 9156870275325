import styled from 'styled-components';

const CheckboxStyle = styled.label`
  display: grid;
  align-items: center;
  grid-template-columns: 30px 1fr;
  .checkbox {
    position: relative;
    background: #fff;
    border: 1px solid #7283ba80;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
  }
  color: #000;
  a {
    color: #e20c18;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
`;

export default CheckboxStyle;
