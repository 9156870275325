import styled from 'styled-components';

const HeaderNav = styled.header`
  position: absolute;
  width: 100%;
  z-index: 10;
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 95%;
    @media screen and (min-width: 1200px) {
      max-width: 80%;
    }
    margin: 0 auto;
    padding-top: 30px;
    img {
      max-width: 320px;
      max-height: 140px;
      height: auto;
      width: auto;
    }
    .spacer {
      flex-basis: 1;
    }
    .menu-container {
      display: flex;
      align-items: center;
      ul {
        display: flex;
        font-family: ${props => props.theme.fontFamily.title};
        font-weight: 400;
        gap: 1rem;
        li {
          position: relative;
          color: ${props => props.theme.colors.textContrast};
          display: flex;
          align-items: center;
          &:hover,
          &:active {
            a:before {
              width: 100%;
              left: 0;
              right: auto;
            }
          }
          a {
            text-align: center;
            color: ${props => props.theme.colors.textContrast};
            cursor: pointer;
            &:before {
              content: '';
              width: 0;
              height: 1px;
              background: #fff;
              position: absolute;
              bottom: 0;
              left: auto;
              right: 0;
              transition: all 0.2s linear;
            }
          }
          &:last-child {
            display: none;
          }
        }
      }
      .dropdown {
        display: flex;
        flex-direction: column;
        .dropdown-content {
          width: 200%;
          max-height: 0px;
          height: auto;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          position: absolute;
          top: 25px;
          left: 0;
          opacity: 0;
          background: #fff;
          border-radius: 4px;
          transition: ease 150ms;
          overflow: hidden;
          a {
            color: #09206a;
            font-size: 13px;
            padding: 10px 10px;
            width: 100%;
            text-align: left;
            transition: 150ms ease;
            &:before {
              display: none;
            }
            &:hover {
              color: ${props => props.theme.colors.primary};
            }
          }
        }
        &:hover {
          .dropdown-content {
            max-height: 500px;
            opacity: 1;
          }
        }
      }
      .btn-navbar {
        background: #fff;
        color: #09206a;
        padding: 15px 60px;
        border-radius: 30px;
        transition: 200ms ease-in-out;
        margin-left: 30px;
        @media screen and (min-width: 1500px) {
          padding: 20px 80px;
        }
        &:hover {
          color: #e20c18;
          box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    .nav-bar .menu-container {
      ul li {
        display: none;
        &:last-child {
          display: block;
          margin: 0;
        }
      }
      .dropdown {
        display: none;
      }
    }
    .btn-navbar {
      display: none;
    }
  }
  @media screen and (max-width: 560px) {
    .nav-bar {
      max-width: 100%;
      padding: 35px;
      img {
        max-width: 200px;
        max-height: 95px;
      }
    }
  }
`;

export default HeaderNav;
