import styled from 'styled-components';

interface CardStyleProps {
  listStyle?: string;
  marginBottom?: string;
}

const Style = styled.section<CardStyleProps>`
  margin: 2em 0;
  background: #ffff;
  width: 100%;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  h2 {
    font-size: 30px;
    font-weight: 600;
    color: ${props => props.theme.colors.textTitle};
  }
  p {
    font-size: ${props => props.theme.fontSize.default};
    color: #7283ba;
    line-height: 1.7;
    white-space: pre-wrap;
    margin-top: 1em;
  }
  a {
    color: #e20b1e;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
  b {
    color: #7283ba;
    font-weight: 600;
  }
  ul {
    color: #7283ba;
    margin-left: 30px;
    ${props =>
      props.listStyle === 'decimal'
        ? 'list-style: decimal;'
        : 'list-style: disc;'}
    li {
      margin-bottom: 8px;
    }
  }
  @media screen and (max-width: 1100px) {
    p {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
  }
  @media screen and (max-width: 560px) {
    p {
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
  }
`;

export default Style;
