import Styled from 'styled-components';
const LoadingStyle = Styled.div`
  display: flex;
  flex:1;
  height:100vh;
  align-items: center;
  justify-content: center;
  min-height: 250px;
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotating 0.75s cubic-bezier(0.25, 0.55, 0.75, 0.5) infinite;
}
`;

export default LoadingStyle;
