import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Loading from './components/loading';
import reportWebVitals from './reportWebVitals';
import './utils/translate';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Suspense fallback={<Loading />}>
    <App />
  </Suspense>
);
reportWebVitals();
