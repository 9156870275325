import styled from 'styled-components';

const Style = styled.div`
  width: 48%;
  padding: 55px;
  box-shadow: 0px 3px 6px #00000020;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 60px;
  h3 {
    color: #e30933;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  p {
    font-size: ${props => props.theme.fontSize.default};
    white-space: pre-wrap;
    color: #7283ba;
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
    padding: 25px;
    p {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
  }
  @media screen and (max-width: 560px) {
    width: 100%;
    padding: 25px;
    p {
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
  }
`;
export default Style;
