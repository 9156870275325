import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { ThemeProvider } from 'styled-components';
import Routers from './Routers';
import CookieBox from './components/cookieBox';
import Global from './global';
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CookieBox />
      <Routers />
      <Global />
    </ThemeProvider>
  );
}

const theme = {
  fontFamily: {
    title: 'Avenir, sans-serif'
  },
  fontSize: {
    default: '18px',
    defaultTablet: '22px',
    defaultMobile: '16px'
  },
  colors: {
    primary: '#E30647',
    textTitle: '#383838',
    textContrast: '#ffffff'
  }
};

export default App;
