import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Form from '../../components/form';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import { InputItem, TextAreaItem } from '../../types/common';
import i18n from '../../utils/translate';

const RelacoesComInvestidores = (): JSX.Element => {
  const b_containerName = 'investidores';
  const { t } = useTranslation();
  const inputs: InputItem[] = [
    {
      type: 'text',
      placeholder: `${t('Nome')}*`,
      name: 'name',
      tamanho: '48%',
      required: true,
      key: 'input1'
    },
    {
      type: 'text',
      placeholder: `${t('E-mail')}*`,
      name: 'email',
      tamanho: '48%',
      required: true,
      key: 'input2'
    },
    {
      type: 'text',
      placeholder: `${t('Telefone (Whatsapp)')}*`,
      name: 'telefone',
      tamanho: '48%',
      required: true,
      key: 'input3',
      mask: '(99) 99999-9999'
    },
    {
      type: 'text',
      placeholder: `${t('Empresa')}*`,
      name: 'empresa',
      tamanho: '48%',
      required: true,
      key: 'input4'
    }
  ];

  const textearea: TextAreaItem[] = [
    {
      type: 'text',
      placeholder: t('Mensagem'),
      name: 'message',
      tamanho: '98%',
      key: 'text4'
    }
  ];
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Relação com Investidores | Play2sell')}</title>
        <meta
          name='description'
          content='Veja como funciona a relação com os investidores da Play2sell.'
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link
          rel='canonical'
          href='https://play2sell.com/relationship-with-investors/'
        />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Relação com Investidores | Play2sell'
        />
        <meta
          property='og:description'
          content='Veja como funciona a relação com os investidores da Play2sell.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/relationship-with-investors/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-05-11T09:21:10-03:00' />

        <meta
          name='twitter:title'
          content='Relação com Investidores | Play2sell'
        />
        <meta
          name='twitter:description'
          content='Veja como funciona a relação com os investidores da Play2sell.'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <Header
          b_container={b_containerName}
          title={t('Relações com Investidores')}
          subtitulo={t('Relações com Investidores')}
        />
        <main className='container-main'>
          <CardText
            b_container={b_containerName}
            b_str='1'
            title={t('Conselho consultivo') || ''}
            text={
              t(
                'Realizamos reuniões do conselho consultivo mensalmente. O conselho é composto pelo CEO, Felipe dos Santos e dois representantes dos investidores. Os membros do referido conselho estão à disposição dos demais.'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='2'
            title={t('Relatórios aos investidores') || ''}
            text={
              t(
                'Para uma maior transparência, elaboramos trimestralmente em conjunto com os membros do conselho consultivo os relatórios aos investidores do primeiro e segundo round. O Report é estruturado com uma mensagem da gestão, informando sobre os highlights e lowlights do período, o funil de vendas, o gráfico de faturamento recorrente e uma mensagem sobre a metas do próximo trimestre. Ele é enviado até o dia 15 do mês subsequente. Os dados são por competência, ou seja, baseados nos demonstrativos de resultado de exercício do trimestre referido.'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title={t('Captação de investimentos') || ''}
            text={
              t(
                'Finalizamos a terceira rodada de investimentos em fevereiro de 2021. Algumas notícias sobre a captação: <a href="https://exame.com/tecnologia/treinar-vendedores-usando-games-startup-especializada-capta-r-2-milhoes/" target="_blank" rel="noreferrer">Exame</a>, <a href="https://forbes.com.br/forbes-tech/2021/02/pagamentos-digitais-em-alta-problemas-na-nuvem-transferwise-ifood-muito-mais/" target="_blank" rel="noreferrer">Forbes</a> e <a href="https://revistapegn.globo.com/Startups/noticia/2021/01/startup-de-treinamentos-corporativos-play2sell-capta-r-2-milhoes.html" target="_blank" rel="noreferrer">Pequenas Empresas & Grandes Negócios.</a>'
              ) || ''
            }
          />
          <Form
            sm
            type='investors'
            inputs={inputs}
            textearea={textearea}
            title={t('Dúvidas')}
            subtitle={`${t(
              'Em caso de dúvidas, basta enviar mensagem através do formulário de contato abaixo'
            )}:`}
            warning={`* ${t('Campo obrigatório')}`}
            button={t('Enviar contato')}
          />
        </main>
      </main>
      <Footer />
    </>
  );
};

export default RelacoesComInvestidores;
