import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer';
import Form from '../../components/form';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import i18n from '../../utils/translate';

const Contato = (): JSX.Element => {
  const { t } = useTranslation();
  const inputs = [
    {
      type: 'text',
      placeholder: `${t('Nome')}*`,
      name: 'name',
      tamanho: '48%',
      required: true,
      key: 'input1'
    },
    {
      type: 'text',
      placeholder: `${t('E-mail')}*`,
      name: 'email',
      tamanho: '48%',
      required: true,
      key: 'input2'
    },
    {
      type: 'text',
      placeholder: `${t('Celular (Whatsapp)')}*`,
      name: 'telefone',
      tamanho: '48%',
      required: true,
      key: 'input3',
      mask: '(99) 99999-9999'
    },
    {
      type: 'text',
      placeholder: `${t('Empresa')}*`,
      name: 'empresa',
      tamanho: '48%',
      required: true,
      key: 'input4'
    }
  ];

  const select = [
    {
      placeholder: `${t('Seu cargo')}*`,
      name: 'job',
      tamanho: '48%',
      required: true,
      key: 'select1',
      options: [
        {
          value: 'CEO / Fundador',
          text: t('CEO / Fundador'),
          key: 'option1'
        },
        {
          value: 'Diretor Comercial',
          text: t('Diretor Comercial'),
          key: 'option2'
        },
        {
          value: 'Gerente Comercial',
          text: t('Gerente Comercial'),
          key: 'option3'
        },
        {
          value: 'Gerente de Business Development(SDR)',
          text: t('Gerente de Business Development(SDR)'),
          key: 'option4'
        },
        {
          value: 'Coordenador de Business Development(SDR)',
          text: t('Coordenador de Business Development(SDR)'),
          key: 'option5'
        },
        {
          value: 'Analista / Especialista em Treinamentos',
          text: t('Analista / Especialista em Treinamentos'),
          key: 'option6'
        },
        {
          value: 'Estudante',
          text: t('Estudante'),
          key: 'option7'
        },
        {
          value: 'Outros',
          text: t('Outros'),
          key: 'option8'
        }
      ]
    },
    {
      placeholder: `${t('Quantos vendedores têm a empresa?')}*`,
      name: 'qtd_sales',
      tamanho: '48%',
      required: true,
      key: 'select2',
      options: [
        {
          value: 'Não possuo vendedores',
          text: t('Não possuo vendedores'),
          key: 'option1'
        },
        {
          value: '1 a 10 vendedores',
          text: t('1 a 10 vendedores'),
          key: 'option2'
        },
        {
          value: '11 a 29 vendedores',
          text: t('11 a 29 vendedores'),
          key: 'option3'
        },
        {
          value: '30 a 50 vendedores',
          text: t('30 a 50 vendedores'),
          key: 'option4'
        },
        {
          value: '51 a 100 vendedores',
          text: t('51 a 100 vendedores'),
          key: 'option5'
        },
        {
          value: 'Mais de 100 vendedores',
          text: t('Mais de 100 vendedores'),
          key: 'option6'
        }
      ]
    },
    {
      placeholder: t('Como nos conheceu?'),
      name: 'how_met',
      tamanho: '48%',
      key: 'select3',
      options: [
        {
          value: 'Redes Sociais',
          text: t('Redes Sociais'),
          key: 'option1'
        },
        {
          value: 'Google',
          text: t('Google'),
          key: 'option2'
        },
        {
          value: 'Indicação',
          text: t('Indicação'),
          key: 'option3'
        },
        {
          value: 'Eventos ou feiras',
          text: t('Eventos ou feiras'),
          key: 'option4'
        },
        {
          value: 'Live, webnar ou evento online',
          text: t('Live, webnar ou evento online'),
          key: 'option5'
        },
        {
          value: 'E-mail',
          text: t('E-mail'),
          key: 'option6'
        }
      ]
    },
    {
      placeholder: t('Qual seu principal objetivo?'),
      name: 'objective',
      tamanho: '48%',
      key: 'select4',
      options: [
        {
          value: 'Capacitar o time',
          text: t('Capacitar o time'),
          key: 'option1'
        },
        {
          value: 'Engajar a equipe',
          text: t('Engajar a equipe'),
          key: 'option2'
        },
        {
          value: 'Melhorar os resultados',
          text: t('Melhorar os resultados'),
          key: 'option3'
        },
        {
          value: 'Outros',
          text: t('Outros'),
          key: 'option4'
        }
      ]
    }
  ];

  const textearea = [
    {
      type: 'text',
      placeholder: t('Mensagem'),
      name: 'message',
      tamanho: '98%',
      key: 'text1'
    }
  ];

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Entre em contato - Play2sell')}</title>
        <meta
          name='description'
          content='Faça como as incorporadoras e imobiliárias mais inovadoras. Capacite os corretores de imóveis de forma divertida e e engajadora. Entre em contato agora!'
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link rel='canonical' href='https://play2sell.com/contact/' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Entre em contato - Play2sell' />
        <meta
          property='og:description'
          content='Faça como as incorporadoras e imobiliárias mais inovadoras. Capacite os corretores de imóveis de forma divertida e e engajadora. Entre em contato agora!'
        />
        <meta property='og:url' content='https://play2sell.com/contact/' />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-05-04T13:51:08-03:00' />

        <meta name='twitter:title' content='Entre em contato - Play2sell' />
        <meta
          name='twitter:description'
          content='Faça como as incorporadoras e imobiliárias mais inovadoras. Capacite os corretores de imóveis de forma divertida e e engajadora. Entre em contato agora!'
        />
      </Helmet>
      <HeaderNav />
      <Header
        title={t('Entre em contato conosco')}
        subtitulo={t('Entre em contato conosco')}
        b_container='contato'
      />
      <main className='container-form'>
        <Form
          type='client'
          title={t('Entre em contato conosco')}
          warning={`* ${t('Campo obrigatório')}`}
          button={t('Enviar Contato')}
          inputs={inputs}
          select={select}
          textearea={textearea}
        />
      </main>
      <Footer />
    </>
  );
};

export default Contato;
