import Styled from 'styled-components';

const HeaderIndexStyle = Styled.div`
  height: 65vh;
  width: 100%;
  font-size: 18px;
  color: ${props => props.theme.colors.textContrast};
  font-family: ${props => props.theme.fontFamily.title};
  background: linear-gradient(155deg, #e20c18, #e30647);
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #fff;
  .hero-container {
    max-width: 95%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin: 0 auto;
    margin-top: 160px;
    z-index: 1;
    position: relative;
    @media screen and (min-width: 1200px) {
      max-width: 80%;
    }
    .hero-info {
      width: 100%;
      margin-bottom: 50px;
      .hero-title {
        font-size: 4em;
        margin-bottom: 10px;
      }
      .hero-text {
        font-size: 15px;
        a:hover {
          text-decoration: underline;
        }
      }
      .hero-buttons {
        display: flex;
        align-items: center;
        .btn-hero {
          display: inline-block;
          padding: 20px 75px;
          border-radius: 30px;
          transition: 200ms ease-in-out;
          background: #fff;
          color: ${props => props.theme.colors.textTitle};
          margin-right: 30px;
          &:hover {
            color: #e20c18;
            box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
          }
        }
        .btn-hero-alt {
          display: inline-block;
          padding: 18px 60px;
          border-radius: 30px;
          transition: 200ms ease-in-out;
          background: #E20C18;
          color: #fff;
          border: 2px solid #fff;
          &:hover {
            background: #fff;
            color: #e20c18;
            box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
          }
        }
      }
    }
    .hero-image {
      display: flex;
      justify-content: center;
      width: 50%;
      position: relative;
    }
  }
@media screen and (min-height: 800px) and (orientation: portrait) {
  height: 45vh;
}
@media screen and (min-height: 1200px) and (orientation: portrait) {
  height: 30vh;
}
@media screen and (max-width: 1400px) {
  .hero-container {
    .hero-info {
      .hero-title {
        font-size: 2.2em;
      }
    }
  }
}
@media screen and (max-width: 560px) {
  max-width: 100%;
  height: 60vh;
  .hero-container {
    padding: 0 35px;
    .hero-info {
      .hero-title {
        font-size: 30px;
      }
    }
  }
}
`;

export default HeaderIndexStyle;
