import { CaretDownFill } from '@styled-icons/bootstrap/CaretDownFill';
import { Menu } from '@styled-icons/entypo/Menu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from '../../utils/translate';
import SideDrawer from '../sideDrawer';
import { Backdrop } from '../sideDrawer/styles';
import HeaderStyle from './styles';
const HeaderNav = (): JSX.Element => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  let backDrop;
  if (sideDrawerOpen) {
    backDrop = <Backdrop onClick={backdropClickHandler} />;
  }

  return (
    <HeaderStyle>
      <nav className='nav-bar'>
        <Link to={'/'}>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FPS2_logo.png?alt=media&token=a30df6ab-87c2-42ba-8eab-c1bc9a314d2d'
            alt='Play2sell Logo'
          />
        </Link>
        <div className='spacer' />
        <div className='menu-container'>
          <ul>
            {menuLanguages.map((item, index) => (
              <li key={index}>
                <a>
                  <img
                    src={item.icon}
                    alt={item.key.toUpperCase()}
                    width='18px'
                    height='12px'
                    onClick={() => i18n.changeLanguage(item.key)}
                  />
                </a>
              </li>
            ))}
            {MenuOptions().map((option, index) => (
              <li key={`${index}_${option.name}`} className='dropdown'>
                {!option.children ? (
                  <Link to={option.link}>
                    {option.name}
                    {option.children ? <CaretDownFill size='16px' /> : null}
                  </Link>
                ) : (
                  <a>
                    {option.name}
                    {option.children ? <CaretDownFill size='16px' /> : null}
                  </a>
                )}
                <div className='dropdown-content'>
                  {option.children?.map((suboption: any, index: number) => (
                    <Link
                      key={`${index}_submenu_${suboption.name}`}
                      to={suboption.link}>
                      {suboption.name}
                    </Link>
                  ))}
                </div>
              </li>
            ))}
            <li>
              <Menu
                onClick={drawerToggleClickHandler}
                width='3rem'
                height='3rem'
              />
            </li>
          </ul>
          <Link to={'https://admin.play2sell.com/login'} className='btn-navbar'>
            {t('Login')}
          </Link>
        </div>
      </nav>
      <SideDrawer className={sideDrawerOpen ? 'open' : ''} />
      {backDrop}
    </HeaderStyle>
  );
};

export const MenuOptions = (): Array<any> => {
  const { t } = useTranslation();
  return [
    {
      name: t('Home'),
      link: '/'
    },
    {
      name: t('Sobre a Empresa'),
      link: '/about'
      // children: [
      // {
      //   name: t('Sobre a Empresa'),
      //   link: '/sabout'
      // },
      // {
      //   name: t('Gamificação'),
      //   link: '/gamification'
      // },
      // {
      //   name: t('Treinamento de vendas'),
      //   link: '/sales-training'
      // },
      // {
      //   name: t('Treinamento para corretor de imóveis'),
      //   link: '/real-estate-agent-training'
      // },
      // {
      //   name: t('Motivação de equipe de vendas'),
      //   link: '/sales-team-motivation'
      // }
      // ]
    },
    {
      name: t('Parceiros'),
      link: '/integration-home'
    },
    {
      name: t('Investidores'),
      link: '/relationship-with-investors'
    },
    // {
    //   name: t('Blog'),
    //   link: 'https://blog.play2sell.com/'
    // },
    {
      name: t('Ajuda'),
      link: 'https://help.play2sell.com/pt-BR/'
    },
    {
      name: t('Vagas'),
      link: '/work-with-us'
    },
    {
      name: t('Contato'),
      link: '/contact'
    }
  ];
};

export const menuLanguages = [
  {
    icon: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fpt_BR.png?alt=media&token=06c43025-c4c5-4b7e-b467-88d3a5f8b5a3',
    key: 'pt'
  },
  {
    icon: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fen_US.png?alt=media&token=72d0c299-92f8-4bad-8afe-4aabeb92b00b',
    key: 'en'
  },
  {
    icon: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fes_ES.png?alt=media&token=a4096348-b76f-4ac0-8cc8-5eacdc1f42eb',
    key: 'es'
  },
  {
    icon: 'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fpt_PT.jpg?alt=media&token=5c46c935-60ff-40db-bb0a-e89a8a55baa0',
    key: 'pt_PT'
  }
];

export default HeaderNav;
