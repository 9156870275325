import Axios from 'axios';
import { HASH, URL_API } from '../environments';
import {
  DataClient,
  DataIntegrations,
  DataInvestors,
  DataNewsLetter,
  DataVacancies,
  LeadType
} from '../types/common';

export const setLead = (
  type: LeadType,
  data:
    | DataClient
    | DataInvestors
    | DataVacancies
    | DataNewsLetter
    | DataIntegrations
): Promise<any> => {
  return new Promise((resolve, reject) => {
    Axios({
      url: `${URL_API}/utils/lead`,
      method: 'POST',
      data: {
        hash: HASH,
        type,
        source: 'site',
        data
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const uploadFiles = (files: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (let index = 0; index < files.length; index++) {
      data.append('files', files[index]);
    }
    data.append('hash', HASH);
    Axios({
      url: `${URL_API}/utils/upload`,
      method: 'POST',
      data
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};
