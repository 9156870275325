import { Check } from '@styled-icons/bootstrap/Check';
import parse from 'html-react-parser';
import CheckboxStyle from './styles';

interface CheckboxProps {
  name: string;
  id: string;
  text: string;
  value: string;
  on_change: any;
  noMargin?: boolean;
}
const CheckboxInput = ({
  name,
  id,
  text,
  value,
  on_change,
  noMargin
}: CheckboxProps) => {
  return (
    <CheckboxStyle
      style={
        !noMargin
          ? {
              marginTop: '20px',
              marginBottom: '30px'
            }
          : undefined
      }>
      <div className='checkbox'>
        {value === 'true' ? (
          <Check width='22px' height='22px' color='#e20c18' />
        ) : null}
      </div>
      <span>{parse(text)}</span>
      <input
        type='checkbox'
        name={name}
        id={id}
        value={value}
        style={{
          visibility: 'hidden',
          opacity: 0,
          width: 0,
          height: 0,
          display: 'none'
        }}
        onChange={on_change}
      />
    </CheckboxStyle>
  );
};

export default CheckboxInput;
