import styled from 'styled-components';

const Style = styled.div`
  background: #ffff;
  width: 50%;
  margin-top: 20px;
  .container {
    display: flex;
    flex-wrap: wrap;
    p {
      width: 100%;
    }
    span.dados {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 25px 13px;
      width: 76%;
    }
    p:first-child {
      font-size: 24px;
      color: ${props => props.theme.colors.textTitle};
      font-weight: 500;
      margin-bottom: 17px;
    }
    p:last-child {
      font-size: ${props => props.theme.fontSize.default};
      color: #7283ba;
      font-weight: 400;
      white-space: pre-wrap;
    }
  }
  span.img {
    width: 100px;
    height: 100px;
    background: #e20c18;
    border-radius: 65px;
    box-shadow: 0px 6px 9px #00000030;
    border: 4px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px;
    color: #fff;
    margin-top: 50px;
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
    .container {
      p:last-child {
        font-size: ${props => props.theme.fontSize.defaultTablet};
      }
    }
  }
  @media screen and (max-width: 560px) {
    width: 100%;
    .container {
      p:last-child {
        font-size: ${props => props.theme.fontSize.defaultMobile};
      }
    }
  }
`;

export default Style;
