import parse from 'html-react-parser';
import Style from './styles';

interface CardTextImageProps {
  b_container: string;
  b_str: string;
  title?: string;
  text?: string;
  image?: string;
  list?: string[];
  listStyle?: string;
  marginBottom?: string;
}

const CardTextImage = ({
  title,
  text,
  image,
  marginBottom,
  list,
  listStyle,
  b_container,
  b_str
}: CardTextImageProps): JSX.Element => {
  return (
    <Style marginBottom={marginBottom} listStyle={listStyle}>
      <div className='card'>
        {title && (
          <h2 b-container={`${b_container}`} b-str={`titulo_${b_str}`}>
            {parse(title)}
          </h2>
        )}
        <p b-container={`${b_container}`} b-str={`texto_${b_str}`}>
          {text && parse(text)}
        </p>
        {list && (
          <ul>
            {list.map((item, key) => (
              <li key={key}>{parse(item)}</li>
            ))}
          </ul>
        )}
      </div>
      <div className='image-container'>
        <img src={image} alt='Selo GPTW' />
      </div>
    </Style>
  );
};

export default CardTextImage;
