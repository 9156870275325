import Styled from 'styled-components';
const InfoAppStyle = Styled.div`
  margin: 160px auto;
  h1 {
    font-size: 2.6rem;
    font-weight: 100;
    color: #e20c18;
    text-align: center;
  }
  h4 {
    font-size: ${(props: any) => props.theme.fontSize.default};
    font-weight: 400;
    color: #7283ba;
    text-align: center;
    margin-top: 16px;
    white-space: pre-wrap;
    margin-bottom: 70px;
  }
  .cards-container{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 30px;
    .card-info {
      width: 265px;
      text-align: center;
      padding: 35px 25px;
      border: 1px solid #E1DFFF;
      border-radius: 15px;
      transition: 200ms ease;
      &:hover, &:focus {
        background: #f0f0f650;
        box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
      }
      .card-image {
        width: 90px;
        height: 90px;
        background: #FCE4E5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 20px;
        border-radius: 30px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .card-title {
        position: relative;
        font-size: 1.5rem;
        margin-bottom: 40px;
        color: #09206a;
        &:after {
          content: '';
          position: absolute;
          width: 40px;
          height: 3px;
          background: #E20C18;
          bottom: -20px;
          left: 50%;
          transform: translate(-50%);
        }
      }
      .card-text {
        color: #7283ba;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    h4 {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
    .cards-container{
    .card-info {
      .card-text {
        font-size: 18px;
      }
    }
  }
  }
  @media screen and (max-width: 800px) {
    .cards-container {
      display: block;
      .card-info {
        height: 450px;
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width: 560px) {
    h4{
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
    .cards-container{
      .card-info {
        .card-text {
          font-size: 16px;
        }
      }
    }
  }
`;

export default InfoAppStyle;
