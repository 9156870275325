import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RedCircle } from '../svg_list';
import HeaderIndexStyle from './styles';

interface HeaderProps {
  b_container: string;
  title: string;
  subtitulo: string;
}

const Header = ({
  b_container,
  title,
  subtitulo
}: HeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <HeaderIndexStyle>
      <img
        src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FGrupo%20de%20m%C3%A1scara%201%402x.png?alt=media&token=d0007c42-58f6-40f1-a80a-4ea85e8abb09'
        alt='imagem de fundo'
        style={{ bottom: 0, position: 'absolute', width: '100%', zIndex: 1 }}
      />
      <img
        src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fsvg_wave_transparent.svg?alt=media&token=49115d51-e954-44f8-bc48-87270955e49a'
        alt='imagem de fundo 2'
        style={{
          position: 'absolute',
          bottom: '25px',
          zIndex: 0
        }}
      />
      <RedCircle
        style={{
          position: 'absolute',
          left: '70%',
          bottom: '50px',
          zIndex: 10,
          transform: 'scalex(-1)',
          animation:
            '45s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s infinite normal none running movingaroundinverted'
        }}
      />
      <div className='hero-container'>
        <div className='hero-info'>
          <div
            className='hero-title'
            b-container={`${b_container}`}
            b-str='header_titulo'>
            {title}
          </div>
          <div className='hero-text'>
            <Link to={'/'}>{t('Home').toUpperCase()}</Link>
            {' > '}
            <span b-container={`${b_container}`} b-str='header_subtitulo'>
              {subtitulo.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
    </HeaderIndexStyle>
  );
};

export default Header;
