import parse from 'html-react-parser';
import Style from './styles';

interface CardTextProps {
  b_container: string;
  b_str: string;
  title?: string;
  text?: string;
  list?: string[];
  listStyle?: string;
  marginBottom?: string;
}

const CardText = ({
  title,
  text,
  marginBottom,
  list,
  listStyle,
  b_container,
  b_str
}: CardTextProps): JSX.Element => {
  return (
    <Style marginBottom={marginBottom} listStyle={listStyle}>
      <div className='card'>
        {title && (
          <h2 b-container={`${b_container}`} b-str={`titulo_${b_str}`}>
            {title && parse(title)}
          </h2>
        )}
        <p b-container={`${b_container}`} b-str={`texto_${b_str}`}>
          {text && parse(text)}
        </p>
        {list && (
          <ul>
            {list.map((item, key) => (
              <li key={key}>{parse(item)}</li>
            ))}
          </ul>
        )}
      </div>
    </Style>
  );
};

export default CardText;
