import { Spinner } from '@styled-icons/fa-solid/Spinner';
import LoadingStyle from './styles';

const Loading = (): JSX.Element => {
  return (
    <LoadingStyle>
      <Spinner className='rotating' size='2rem' title='carregando...' />
    </LoadingStyle>
  );
};

export default Loading;
