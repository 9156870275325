import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';

const IntegracaoSigavi = (): JSX.Element => {
  const b_containerName = 'integracao';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Play2sell + Sigavi')}</title>
        <meta
          name='description'
          content='Integração da Plataforma Play2sell com Sigavi.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Play2sell + Sigavi' />
        <meta
          property='og:description'
          content='Integração da Plataforma Play2sell com Sigavi.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/integration-sigavi/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T10:55:35-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta property='og:image:width' content='800' />
        <meta property='og:image:height' content='447' />
        <meta property='og:image:alt' content='Play2sell + Sigavi' />
        <meta property='og:image:type' content='image/png' />

        <meta
          name='twitter:title'
          content='Integração da Plataforma Play2sell com Sigavi.'
        />
        <meta
          name='twitter:description'
          content='Integração da Plataforma Play2sell com Sigavi.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <Header
          b_container={b_containerName}
          title={t('Play2sell + Sigavi')}
          subtitulo={t('CRM Sigavi')}
        />
        <main className='container-main'>
          <img src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FLogoAzul-sigavi.png?alt=media&token=8166a6fc-c63a-4ccb-aa26-003276be83da' />
          <CardText
            b_container={b_containerName}
            b_str='1'
            title={
              t(
                'A Plataforma Play2sell está integrada com as melhores soluções do mercado'
              ) || ''
            }
            text={
              t(
                'Sigavi 360 é um software de gestão desenvolvido para imobiliárias e incorporadoras. Além de oferecer uma solução que se adapta ao momento do mercado. Você tem acesso a informações sobre os imóveis, leads e colaboradores e consegue medir com facilidade o desempenho e da sua equipe em todas as etapas do processo de vendas'
              ) || ''
            }
          />
          <CardImage
            b_container={b_containerName}
            title=''
            imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2Fmaxresdefault.jpg?alt=media&token=2072d368-3214-40de-8cd8-5bdf4b506058'
            img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2Fmaxresdefault.jpg?alt=media&token=2072d368-3214-40de-8cd8-5bdf4b506058'
          />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title=''
            text={
              t(
                'A plataforma Play2sell gamifica seu processo comercial, unindo capacitação e incentivo por meio de games personalizados, trazendo mais engajamento para sua equipe de vendas. E com a integração do Sigavi 360 CRM, é possível aumentar a agilidade dos processos de seu negócio e tornar a tomada de decisão muito mais assertiva evitando riscos, desperdícios e retrabalho.'
              ) || ''
            }
          />

          <p style={{ color: 'red' }}>
            <a
              href='https://www.sigavi360.com.br/'
              style={{ color: 'red' }}
              target='_blank'
              rel='noreferrer'>
              {t('Saiba mais sobre o CRM Sigavi!')}
            </a>
          </p>

          <ContatoCaller
            b_container={b_containerName}
            b_str='4'
            title=''
            buttonText={t('Vamos conversar?') || ''}
          />
        </main>
      </main>
      <Footer />
    </>
  );
};
export default IntegracaoSigavi;
