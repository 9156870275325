import { useTranslation } from 'react-i18next';
import { QuotesIcon } from '../svg_icons';
import { RedCircle, WaveSVG } from '../svg_list';
import DepoimentosStyle from './styles';

const Depoimentos = (): JSX.Element => {
  const { t } = useTranslation();
  const listDepoiments = [
    {
      name: 'Jhonny Blaze',
      jobTitle: t('Vendedor Varejista'),
      text: t(
        'O jogo veio para ajudar a gente! Depois que começamos a jogar, alavancou bastante as vendas. É uma experiência legal!'
      ),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/depoimentos%2FJhonny-Blaze.jpg?alt=media&token=1d0646ae-3099-4360-a59c-18c1bdde212a'
    },
    {
      name: 'Roseli (Roseli Ferreira Gomes)',
      jobTitle: t('Corretora de Imóveis'),
      text: t(
        'O game é muito funcional para o que eu preciso hoje! Ajuda muito na hora de falar com o cliente, seja presencial ou por telefone.'
      ),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fdepoimentos%2Froseli_-_mac.jpg?alt=media&token=9bbe2a37-f1e3-4911-94b3-c3ded3866421'
    },
    {
      name: 'Martini (Daniela Aparecida Pio De Carvalho Martini)',
      jobTitle: t('Corretora de Imóveis'),
      text: t(
        'Acredito que o game nos faz memorizar melhor as coisas do dia a dia! Gosto desse jeito de aprender'
      ),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/depoimentos%2FMartini%20mvituzzo%20(1)%20(1).jpeg?alt=media&token=f8a45da4-dbe1-429a-8b08-4ca0a6ed7dde'
    },
    {
      name: 'Sefora (Andrea Ferreira de Paulo Zeferino)',
      jobTitle: t('Corretora de Imóveis'),
      text: t(
        'Gosto bastante do jogo! Achei muito interessante. E também, além de aprendermos, para quem gosta de disputar com os colegas, é bem legal.'
      ),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Fdepoimentos%2Fsefora_-_mac.jpg?alt=media&token=427ba9cc-ed7a-4b48-95b9-f952304b1720'
    },
    {
      name: 'Anderson Apolinário',
      jobTitle: t('Assistente de captação'),
      text: t(
        'Gostei! Vendi duas matrículas do colégio a mais, aplicando justamente o quiz de técnica de vendas, sobre mostrar o produto e conhecer sobre ele. Quando conhece, fica mais fácil de vender.'
      ),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/depoimentos%2FAnderson%20Apolin%C3%A1rio.png?alt=media&token=7b1bb398-fc10-489a-b49f-aefeaa8d6bae'
    },
    {
      name: 'Elcimar Carlos Mendonça',
      jobTitle: t('Supervisor de captação, líder de vendas'),
      text: t(
        'Eu já participei de ações de incentivo de outras empresas, e achei muito legal essa dinâmica… Incentiva a equipe. O processo acaba saindo natural, o reforço do game proporcionou isso.'
      ),
      image:
        'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/depoimentos%2FCarlos%20Mendon%C3%A7a.png?alt=media&token=b0a22407-545a-46ba-acdc-7c6f03d8c707'
    }
  ];

  const DepoimentoCard = (props: any) => {
    return (
      <div className={`depoimento-card ${props.className}`}>
        <div
          b-container={`depoimentos`}
          b-str={props.strDepoimento}
          className='card-message'>
          {props.message}
        </div>
        <div className='card-info'>
          <picture className='card-image'>
            <source srcSet={props.imageWebp} type='image/webp' />
            <source srcSet={props.image} type='image/jpeg' />
            <img
              b-container={`depoimentos`}
              b-img={props.imgDepoimento}
              src={props.image}
              alt='foto depoimento'
            />
          </picture>
          <div className='card-container'>
            <div
              b-container={`depoimentos`}
              b-str={props.strName}
              className='card-name'>
              {props.name}
            </div>
            <div
              b-container={`depoimentos`}
              b-str={props.strSubtitle}
              className='card-subtitle'>
              {props.subtitle}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <DepoimentosStyle>
      <RedCircle
        style={{ position: 'absolute', left: 70, top: -30, zIndex: 1 }}
      />
      <RedCircle
        style={{ position: 'absolute', right: 15, bottom: 135, zIndex: 2 }}
      />
      <div className='bg-container'>
        <WaveSVG
          style={{
            position: 'absolute',
            bottom: 0,
            zIndex: 1
          }}
        />
        <div className='depoimentos-container container-narrow'>
          <div className='text-container'>
            <h1 b-container={`depoimentos`} b-str='titulo'>
              {t('O que os usuários dizem')}...
            </h1>
            <QuotesIcon
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: -1
              }}
            />
          </div>
          <div className='cards-container'>
            {listDepoiments.map((x: any, i: number) => (
              <DepoimentoCard
                key={i}
                name={x.name}
                subtitle={x.jobTitle}
                message={x.text}
                image={x.image}
                imageWebp={x.image}
                strDepoimento='depoimento_1'
                imgDepoimento='foto_1'
                strName='nome_1'
                strSubtitle='subtitulo_1'
              />
            ))}
          </div>
        </div>
      </div>
    </DepoimentosStyle>
  );
};

export default Depoimentos;
