import styled from 'styled-components';

const Style = styled.div`
  padding: 4em 0;
  background: #ffff;
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default Style;
