import parse from 'html-react-parser';
import Style from './styles';
interface CardProps {
  b_container: string;
  b_str: string;
  title: string;
  text: string;
}

const CardTextRed = ({
  title,
  text,
  b_container,
  b_str
}: CardProps): JSX.Element => {
  return (
    <Style>
      <div className='card'>
        <h2 b-container={`${b_container}`} b-str={`titulo_alt_${b_str}`}>
          {parse(title)}
        </h2>
        <p b-container={`${b_container}`} b-str={`texto_alt_${b_str}`}>
          {parse(text)}
        </p>
      </div>
    </Style>
  );
};
export default CardTextRed;
