import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { LeftCurvySVG, RightCurvySVG } from '../svg_list';
import ExperienceContainerStyle from './styles';

const ExperienceContainer = () => {
  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  }, []);

  return (
    <ExperienceContainerStyle>
      <LeftCurvySVG className='svg-left' />
      <RightCurvySVG className='svg-right' />
      <div className='experience-container'>
        <div className='experience-slider-container'>
          {isMobile ? (
            <Slider {...settings}>
              <div>
                <a
                  href='https://forbes.com.br/forbes-tech/2021/02/pagamentos-digitais-em-alta-problemas-na-nuvem-transferwise-ifood-muito-mais/'
                  target='_blank'
                  rel='noreferrer'
                  className='img-container'>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fforbes-logo-4.png?alt=media&token=5330e4cf-0238-4993-9faf-e57bd40ff16e'
                    alt='Logo Forbes'
                  />
                </a>
              </div>
              <div>
                <a
                  href='https://valor.globo.com/carreira/noticia/2021/09/17/treinar-equipes-de-vendas-e-o-maior-obstaculo-para-ampliar-negocios.ghtml'
                  target='_blank'
                  rel='noreferrer'
                  className='img-container'>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Flogo-valor-economico.png?alt=media&token=4331cbab-b3d9-44a2-a56f-cd3da0168c9b'
                    alt='Logo Valor Econômico'
                  />
                </a>
              </div>
              <div>
                <a
                  href='https://politica.estadao.com.br/blogs/fausto-macedo/por-que-a-gamificacao-e-tendencia-em-treinamentos-corporativos/#:~:text=Indo%20al%C3%A9m%20do%20entrete'
                  className='img-container'
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Festadao-logo-3-1_rsz.png?alt=media&token=a3cbeb20-e3ad-420d-87fe-e9574a950bf7'
                    alt='Logo Estadão'
                  />
                </a>
              </div>
              <div>
                <a
                  href='https://revistapegn.globo.com/Startups/noticia/2021/01/startup-de-treinamentos-corporativos-play2sell-capta-r-2-milhoes'
                  className='img-container'
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FPequenas-Empresas-Grandes-Neg%C3%B3cios-logo.png?alt=media&token=b9b5a71f-c35c-40ce-acbe-f7d1bc3eb7f5'
                    alt='Logo Pequenas empresas & Grandes Negócios'
                  />
                </a>
              </div>
              <div>
                <a
                  href='https://exame.com/tecnologia/treinar-vendedores-usando-games-startup-especializada-capta-r-2-milhoes/'
                  target='_blank'
                  rel='noreferrer'
                  className='img-container'>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Festadao-logo-3-1_rsz.png?alt=media&token=a3cbeb20-e3ad-420d-87fe-e9574a950bf7'
                    alt='Logo Exame'
                  />
                </a>
              </div>
            </Slider>
          ) : (
            <>
              <a
                href='https://forbes.com.br/forbes-tech/2021/02/pagamentos-digitais-em-alta-problemas-na-nuvem-transferwise-ifood-muito-mais/'
                className='img-container'
                target='_blank'
                rel='noreferrer'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fforbes-logo-4.png?alt=media&token=5330e4cf-0238-4993-9faf-e57bd40ff16e'
                  alt='Logo Forbes'
                />
              </a>
              <a
                href='https://valor.globo.com/carreira/noticia/2021/09/17/treinar-equipes-de-vendas-e-o-maior-obstaculo-para-ampliar-negocios.ghtml'
                target='_blank'
                rel='noreferrer'
                className='img-container'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Flogo-valor-economico.png?alt=media&token=4331cbab-b3d9-44a2-a56f-cd3da0168c9b'
                  alt='Logo Valor Econômico'
                />
              </a>
              <a
                href='https://politica.estadao.com.br/blogs/fausto-macedo/por-que-a-gamificacao-e-tendencia-em-treinamentos-corporativos/#:~:text=Indo%20al%C3%A9m%20do%20entrete'
                className='img-container'
                target='_blank'
                rel='noreferrer'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/images%2Flogo-estadao-branco-maior.png?alt=media&token=25d033d3-3c6f-486b-b321-e2989468b8ba'
                  alt='Logo Estadão'
                />
              </a>
              <a
                href='https://revistapegn.globo.com/Startups/noticia/2021/01/startup-de-treinamentos-corporativos-play2sell-capta-r-2-milhoes'
                className='img-container'
                target='_blank'
                rel='noreferrer'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FPequenas-Empresas-Grandes-Neg%C3%B3cios-logo.png?alt=media&token=b9b5a71f-c35c-40ce-acbe-f7d1bc3eb7f5'
                  alt='Logo Pequenas empresas & Grandes Negócios'
                />
              </a>
              <a
                href='https://exame.com/tecnologia/treinar-vendedores-usando-games-startup-especializada-capta-r-2-milhoes/'
                className='img-container'
                target='_blank'
                rel='noreferrer'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2Fexame-logo-3-1_rsz.png?alt=media&token=6069407d-58af-478f-badd-c3a702903bdd'
                  alt='Logo Exame'
                />
              </a>
            </>
          )}
        </div>
      </div>
    </ExperienceContainerStyle>
  );
};

export default ExperienceContainer;
