import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Form from '../../components/form';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import Integrador from '../../components/integrador';
import { InputItem, TextAreaItem } from '../../types/common';
import i18n from '../../utils/translate';

const IntegracaoHome = (): JSX.Element => {
  const { t } = useTranslation();
  const inputs: InputItem[] = [
    {
      type: 'text',
      placeholder: `${t('Nome')}*`,
      name: 'name',
      tamanho: '48%',
      required: true,
      key: 'input1'
    },
    {
      type: 'text',
      placeholder: `${t('E-mail')}*`,
      name: 'email',
      tamanho: '48%',
      required: true,
      key: 'input2'
    },
    {
      type: 'text',
      placeholder: `${t('Telefone (Whatsapp)')}*`,
      name: 'telefone',
      tamanho: '48%',
      required: true,
      key: 'input3',
      mask: '(99) 99999-9999'
    },
    {
      type: 'text',
      placeholder: `${t('Empresa')}*`,
      name: 'empresa',
      tamanho: '48%',
      required: true,
      key: 'input4'
    }
  ];

  const textearea: TextAreaItem[] = [
    {
      type: 'text',
      placeholder: t('Mensagem'),
      name: 'message',
      tamanho: '98%',
      key: 'text4'
    }
  ];
  const b_containerName = 'integradores';
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Parceiros e ferramentas Integradas')}</title>
        <meta
          name='description'
          content='A integração de um aplicativo de gamificação de vendas com outras ferramentas do mercado como CRM por exemplo, auxilia na gestão eficaz das vendas'
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link rel='canonical' href='https://play2sell.com/integration-home/' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Parceiros e ferramentas Integradas a Plataforma Play2sell'
        />
        <meta
          property='og:description'
          content='A integração com a automatização vai potencializar a eficácia da sua gestão'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/integration-home/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-05-11T09:21:10-03:00' />

        <meta
          name='twitter:title'
          content='Parceiros e ferramentas Integradas a Plataforma Play2sell'
        />
        <meta
          name='twitter:description'
          content='A integração com a automatização, vai potencializar a eficácia da sua gestão.'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <Header
          b_container={b_containerName}
          title={t('Parceiros e Integrações')}
          subtitulo=''
        />

        <main className='container-main'>
          <CardText
            b_container={b_containerName}
            b_str='1'
            title={t('Parceiros') || ''}
            text={
              t(
                'A parceria complementa e fortalece nosso ecossistema formando uma grande rede de soluções inovadoras com alto padrão de qualidade para resoluções dos problemas e dores do mercado de vendas. Seja parceiro também, preencha o formulário abaixo que entraremos em contato!'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='2'
            title={
              t(
                'Integramos com sistemas como CRM´s, ERP´s, gerenciadores de tarefas e controladores de vendas'
              ) || ''
            }
            text={
              t(
                'A integração do App Play2sell GO com outras ferramentas do mercado como CRM(gestão de relacionamento com o cliente) por exemplo, automatiza e potencializa o acompanhamento na gestão e na eficácia de suas vendas. Esta integração permite que os usuários usem os recursos do aplicativo de gamificação para motivar os funcionários de vendas, ao mesmo tempo em que utilizam os recursos do CRM para monitorar o desempenho das equipes, aumentar a produtividade, melhorar a experiência dos clientes, entre outros. Além disso, está integração também fornece insights e análises avançadas para ajudar as empresas a tomar as melhores decisões de negócio.'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title={`${t('Ferramentas que integramos e somos parceiros')}:`}
            text={''}
          />
          <Integrador />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title={
              t(
                'Saiba como funciona a integração através da documentação de API'
              ) || ''
            }
            text={
              t(
                'A Play2sell disponibiliza a documentação das APIs de forma pública para clientes, parceiros de integração.'
              ) || ''
            }
          />
          <Form
            sm
            type='integration'
            inputs={inputs}
            textearea={textearea}
            title={t('Seja parceiro ou integrado a Plataforma Play2sell!')}
            subtitle={`${t('Envie-nos o formulário de contato abaixo')}:` || ''}
            warning={`* ${t('Campo obrigatório')}`}
            button={t('Enviar')}
          />
        </main>
      </main>
      <Footer />
    </>
  );
};
export default IntegracaoHome;
