import Styled from 'styled-components';

const ErrorStyle = Styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: ${props => props.theme.fontFamily.title};
  text-align: center;
  h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 10px;
  }
`;

export default ErrorStyle;
