import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';

const IntegracaoHypnobox = (): JSX.Element => {
  const b_containerName = 'integracao';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Play2sell + Hypnobox')}</title>
        <meta
          name='description'
          content='Integração da Plataforma Play2sell com Hypnobox.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Play2sell + Hypnobox' />
        <meta
          property='og:description'
          content='Integração da Plataforma Play2sell com Hypnobox.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/integration-hypnobox/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T10:55:35-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
        <meta property='og:image:width' content='800' />
        <meta property='og:image:height' content='447' />
        <meta property='og:image:alt' content='Play2sell + Hypnobox' />
        <meta property='og:image:type' content='image/png' />

        <meta
          name='twitter:title'
          content='Integração da Plataforma Play2sell com Hypnobox.'
        />
        <meta
          name='twitter:description'
          content='Integração da Plataforma Play2sell com Hypnobox.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20de%20vendas.jpg?alt=media&token=8f4896a8-2e9c-4808-8c1e-e55652bc445c'
        />
      </Helmet>
      <HeaderNav />
      <main>
        <Header
          b_container={b_containerName}
          title={t('Play2sell + Hypnobox')}
          subtitulo={t('CRM Hypnobox')}
        />
        <main className='container-main'>
          <img src='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2FLogo-hypnobox.png?alt=media&token=f80c89a5-e158-4900-a76f-5b5c27b59cf2' />
          <CardText
            b_container={b_containerName}
            b_str='1'
            title={
              t(
                'A Plataforma Play2sell está integrada com as melhores soluções do mercado'
              ) || ''
            }
            text={
              t(
                'Hypnobox é o CRM para o mercado imobiliário com Business Intelligence. Oferece soluções de ponta a ponta para desenvolvedores. Oferece integração de múltiplos canais - chat, e-mail, telefone e WhatsApp, distribuição automatizada de leads com identificação da origem do lead e da mídia. Espelho de curativos e tabela de preços e relatórios personalizados.'
              ) || ''
            }
          />
          <CardImage
            b_container={b_containerName}
            title=''
            imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2F621917bc9ea43b24db4d6398_Mask%20group.png?alt=media&token=272955e3-226b-43c8-a0dc-8521821178b5'
            img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/integradores%2F621917bc9ea43b24db4d6398_Mask%20group.png?alt=media&token=272955e3-226b-43c8-a0dc-8521821178b5'
          />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title=''
            text={
              t(
                'A plataforma Play2sell gamifica seu processo comercial, unindo capacitação e incentivo por meio de games personalizados, trazendo mais engajamento para sua equipe de vendas. E com a integração do Hypnobox CRM, é possível aumentar a agilidade dos processos de seu negócio e tornar a tomada de decisão muito mais assertiva evitando riscos, desperdícios e retrabalho.'
              ) || ''
            }
          />

          <p style={{ color: 'red' }}>
            <a
              href='https://hypnobox.com.br/'
              style={{ color: 'red' }}
              target='_blank'
              rel='noreferrer'>
              {t('Saiba mais sobre o CRM Hypnobox!')}
            </a>
          </p>

          <ContatoCaller
            b_container={b_containerName}
            b_str='4'
            title=''
            buttonText={t('Vamos conversar?') || ''}
          />
        </main>
      </main>
      <Footer />
    </>
  );
};

export default IntegracaoHypnobox;
