import { useTranslation } from 'react-i18next';
import ErrorStyle from './styles';

const Error = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorStyle>
      <h2>404</h2>
      <p>{t('Página não encontrada!')}</p>
      <p>{t('Houve um erro inesperado, volte daqui a alguns minutos.')}</p>
    </ErrorStyle>
  );
};

export default Error;
