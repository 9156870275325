import styled from 'styled-components';
const Style = styled.div`
  background: #ffff;
  max-width: 100%;
  margin-top: 1em;
  h1 {
    color: ${props => props.theme.colors.textTitle};
  }
  .image-box {
    width: 100%;
    display: inline-flex;
    margin: 22px 0px;
    min-height: 300px;
    background: #6e6e6e;
    border-radius: 7px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 560px) {
    .image-box {
      min-height: auto;
      height: 125px;
    }
  }
`;
export default Style;
