import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import i18n from '../../utils/translate';

const AgradecemosContato = (): JSX.Element => {
  const b_containerName = 'agradecemos_contato';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('A Play2sell agradece o contato!')}</title>
        <meta
          name='description'
          content={t('Agradecemos seu contato!') || ''}
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link rel='canonical' href='https://play2sell.com/thanks' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Agradecemos seu contato | Play2sell'
        />
        <meta property='og:description' content='Agradecemos seu contato' />
        <meta property='og:url' content='https://play2sell.com/thanks' />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-04-27T14:13:46-03:00' />

        <meta name='twitter:title' content='Oi' />
        <meta name='twitter:description' content='Obrigado pelo seu contato' />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_containerName}
        title={t('A Play2sell agradece o contato!')}
        subtitulo={t('Responderemos logo mais!')}
      />
      <Footer />
    </>
  );
};
export default AgradecemosContato;
