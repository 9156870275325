import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  debug: false,
  fallbackLng: 'pt',
  returnEmptyString: false
});

export const languageCurrent = () => {
  switch (i18n.language) {
    case 'pt':
      return 'Português';
    case 'es':
      return 'Espanhol';
    case 'en':
      return 'Inglês';
    case 'pt_PT':
      return 'Português';
    default:
      return 'Português';
  }
};

export default i18n;
