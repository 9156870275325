import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardTextImage from '../../components/cardTextImage';
import Footer from '../../components/footer';
import Form from '../../components/form';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import { InputItem, TextAreaItem } from '../../types/common';
import i18n from '../../utils/translate';

const TrabalheConosco = (): JSX.Element => {
  const { t } = useTranslation();

  const inputs: InputItem[] = [
    {
      type: 'text',
      placeholder: `${t('Nome')}*`,
      name: 'name',
      tamanho: '48%',
      required: true,
      key: 'input1'
    },
    {
      type: 'text',
      placeholder: `${t('E-mail')}*`,
      name: 'email',
      tamanho: '48%',
      required: true,
      key: 'input2'
    },
    {
      type: 'text',
      placeholder: `${t('Telefone')}*`,
      name: 'telefone',
      tamanho: '48%',
      required: true,
      key: 'input3',
      mask: '(99) 99999-9999'
    },
    {
      type: 'text',
      placeholder: `${t('Formação')}*`,
      name: 'academics',
      tamanho: '48%',
      required: true,
      key: 'input4'
    },
    {
      type: 'text',
      placeholder: t('Para qual área você está se candidatando?'),
      name: 'area',
      tamanho: '48%',
      required: true,
      key: 'input5'
    },
    {
      type: 'text',
      placeholder: `${t('www.linkedin.com/in/exemplo')}*`,
      name: 'linkedin',
      tamanho: '48%',
      required: true,
      key: 'input6'
    }
  ];

  const textearea: TextAreaItem[] = [
    {
      type: 'text',
      placeholder: `${t('Mensagem')}*`,
      name: 'message',
      tamanho: '98%',
      required: true,
      key: 'textArea1'
    }
  ];

  const b_containerName = 'trabalhe_conosco';
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Trabalhe conosco | Play2sell')}</title>
        <meta
          name='description'
          content='Quer trabalhar na Play2sell? Entre em contato conosco e envie o seu currículo!'
        />
        <meta
          name='robots'
          content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        />
        <link rel='canonical' href='https://play2sell.com/work-with-us/' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Trabalhe conosco | Play2sell' />
        <meta
          property='og:description'
          content='Quer trabalhar na Play2sell? Entre em contato conosco e envie o seu currículo!'
        />
        <meta property='og:url' content='https://play2sell.com/work-with-us/' />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-04-27T14:13:46-03:00' />
        <meta name='twitter:title' content='Trabalhe conosco | Play2sell' />
        <meta
          name='twitter:description'
          content='Quer trabalhar na Play2sell? Entre em contato conosco e envie o seu currículo!'
        />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_containerName}
        title={t('Trabalhe Conosco')}
        subtitulo={t('Trabalhe Conosco')}
      />
      <main className='container-form'>
        <CardTextImage
          image={
            'https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/site%202.0%2FGPTW-2023.png?alt=media&token=5dac66f1-2eab-49ba-9ca5-6a4740004ed6'
          }
          b_container={b_containerName}
          b_str='1'
          title={t('Venha inovar com a gente!') || ''}
          text={
            t(
              'Somos um time formado por pessoas apaixonadas em realizar sonhos! Nosso propósito é transformar vendedores de forma divertida gerando resultados extraordinários.\n\nA Play2sell é uma empresa certificada como <a href="https://certificadas.gptw.com.br/certificada/play2sell" target="_blank" rel="noreferrer">Great Place to Work</a>. Investimos no desenvolvimento do time e valorizamos muito o clima descontraído e divertido. Somos todos fanáticos por resultados. A inovação está no nosso DNA.\n\nSeja um Player você também! Venha fazer parte do nosso time!'
            ) || ''
          }
        />
        <Form
          sm
          type='vacancies'
          inputs={inputs}
          textearea={textearea}
          title={t('Trabalhe conosco')}
          warning={`* ${t('Campo obrigatório')}`}
          button={t('Enviar Contato')}
          uploadButton={t('Adicionar Arquivo') || ''}
          uploadWarning={t('Insira seu Currículo em pdf ou docx até 5Mb') || ''}
        />
      </main>
      <Footer />
    </>
  );
};
export default TrabalheConosco;
