import styled from 'styled-components';

const Style = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;
  margin-top: 20px;
  .container-check {
    width: 100px;
    height: 100px;
    background: #e20c18;
    color: #fff;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 6px 9px #00000030;
  }
  .title {
    font-size: 28px;
    color: ${props => props.theme.colors.textTitle};
    margin-bottom: 10px;
  }
  p.text {
    font-size: ${props => props.theme.fontSize.default};
    color: #7283ba;
    white-space: pre-wrap;
  }
  @media screen and (max-width: 1100px) {
    p.text {
      font-size: ${props => props.theme.fontSize.defaultTablet};
      color: #7283ba;
      white-space: pre-wrap;
    }
  }
  @media screen and (max-width: 560px) {
    width: 100%;
    grid-template-columns: 50px 1fr;
    p.text {
      font-size: ${props => props.theme.fontSize.defaultMobile};
      color: #7283ba;
      white-space: pre-wrap;
    }
    .container-check {
      width: 50px;
      height: 50px;
    }
  }
`;

export default Style;
