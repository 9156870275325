import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Style from './styles';

interface ContatoCallerProps {
  b_container: string;
  b_str: string;
  title?: string;
  buttonText?: string;
}

const ContatoCaller = ({
  title,
  buttonText,
  b_container,
  b_str
}: ContatoCallerProps): JSX.Element => {
  return (
    <Style>
      <div
        b-container={`${b_container}`}
        b-str={`texto_contato_${b_str}`}
        className='title'>
        {title && parse(title)}
      </div>
      <Link to={'/contact'} className='button'>
        {buttonText}
      </Link>
    </Style>
  );
};
export default ContatoCaller;
