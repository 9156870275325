import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AgradecemosContato from './pages/agradecimento';
import Contato from './pages/contato';
import Error from './pages/error';
import Gamificacao from './pages/gamificacao';
import Home from './pages/home';
import IntegracaoBitrix from './pages/integracaoBitrix';
import IntegracaoCv from './pages/integracaoCv';
import IntegracaoHome from './pages/integracaoHome';
import IntegracaoHypnobox from './pages/integracaoHypnobox';
import IntegracaoSigavi from './pages/integracaoSigavi';
import IntegracaoVista from './pages/integracaoVista';
import MotivacaoEquipeVendas from './pages/motivacaoDaEquipeDeVendas';
import PoliticaPrivacidade from './pages/politicaDePrivacidade';
import RelacoesComInvestidores from './pages/relacoesComInvestidores';
import SobreAEmpresa from './pages/sobreAEmpresa';
import TermosDeUso from './pages/termosDeUso';
import TermosDeUsoP2SPay from './pages/termosDeUsoP2SPay';
import TrabalheConosco from './pages/trabalheConosco';
import TreinamentoDeVendas from './pages/treinamentoDeVendas';
import TreinamentoParaCorretorDeImoveis from './pages/treinamentoParaCorretorDeImoveis';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <Error />
  },
  {
    path: '/about',
    // path: '/sobre-a-empresa',
    element: <SobreAEmpresa />
  },
  {
    path: '/gamification',
    // path: '/gamificacao',
    element: <Gamificacao />
  },
  {
    path: '/sales-training',
    // path: '/treinamento-de-vendas',
    element: <TreinamentoDeVendas />
  },
  {
    path: '/real-estate-agent-training',
    // path: '/treinamento-para-corretor-de-imoveis',
    element: <TreinamentoParaCorretorDeImoveis />
  },
  {
    path: '/sales-team-motivation',
    // path: '/motivacao-da-equipe-de-vendas',
    element: <MotivacaoEquipeVendas />
  },
  {
    path: '/integration-home',
    // path: '/integracao-home',
    element: <IntegracaoHome />
  },
  {
    path: '/relationship-with-investors',
    // path: '/relacoes-com-investidores',
    element: <RelacoesComInvestidores />
  },
  {
    path: '/contact',
    // path: '/contato',
    element: <Contato />
  },
  {
    path: '/work-with-us',
    // path: '/trabalhe-conosco',
    element: <TrabalheConosco />
  },
  {
    path: '/privacy-policy',
    // path: '/politica-de-privacidade',
    element: <PoliticaPrivacidade />
  },
  {
    path: '/terms-of-use',
    // path: '/termos-de-uso',
    element: <TermosDeUso />
  },
  {
    path: '/p2spay-adhesion-agreement',
    // path: '/termos-de-uso',
    element: <TermosDeUsoP2SPay />
  },
  {
    path: '/integration-bitrix',
    // path: '/integracao-bitrix',
    element: <IntegracaoBitrix />
  },
  {
    path: '/integration-sigavi',
    element: <IntegracaoSigavi />
  },
  {
    path: '/integration-vista',
    element: <IntegracaoVista />
  },
  {
    path: '/integration-cv',
    element: <IntegracaoCv />
  },
  {
    path: '/integration-hypnobox',
    element: <IntegracaoHypnobox />
  },
  {
    path: '/thanks',
    // path: '/agradecimento',
    element: <AgradecemosContato />
  }
]);

const Routers = () => {
  return <RouterProvider router={router} />;
};
export default Routers;
