import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardInfos from '../../components/cardInfos';
import CardNumber from '../../components/cardNumber';
import CardText from '../../components/cardText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';
import Style from './styles';

const TreinamentoParaCorretorDeImoveis = (): JSX.Element => {
  const b_containerName = 'treinamento_corretor';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Treinamento para Corretor de Imóveis - Play2sell')}</title>
        <meta
          name='description'
          content='O Treinamento para Corretor de Imóveis é a melhor forma de treinar sua equipe e gerar mais vendas. Descubra como capacitar seus corretores.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Treinamento para Corretor de Imóveis - Play2sell'
        />
        <meta
          property='og:description'
          content='O Treinamento para Corretor de Imóveis é a melhor forma de treinar sua equipe e gerar mais vendas. Descubra como capacitar seus corretores.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/real-estate-agent-training/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T10:55:37-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20para%20corretores.jpg?alt=media&token=fd970c54-f8a3-42fb-ba4b-b456d61a880e'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20para%20corretores.jpg?alt=media&token=fd970c54-f8a3-42fb-ba4b-b456d61a880e'
        />
        <meta property='og:image:width' content='800' />
        <meta property='og:image:height' content='533' />
        <meta
          property='og:image:alt'
          content='Treinamento para Corretor de Imóveis - Play2sell'
        />
        <meta property='og:image:type' content='image/jpeg' />
        <meta
          name='twitter:title'
          content='Treinamento para Corretor de Imóveis - Play2sell'
        />
        <meta
          name='twitter:description'
          content='O Treinamento para Corretor de Imóveis é a melhor forma de treinar sua equipe e gerar mais vendas. Descubra como capacitar seus corretores.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20para%20corretores.jpg?alt=media&token=fd970c54-f8a3-42fb-ba4b-b456d61a880e'
        />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_containerName}
        title={t('Treinamento para corretor de imóveis')}
        subtitulo={t('Treinamento para corretor de imóveis')}
      />
      <main>
        <Style>
          <ContatoCaller
            b_container={b_containerName}
            b_str='1'
            title={
              t(
                'Aumente as vendas dos seus corretores com treinamentos gamificados'
              ) || ''
            }
            buttonText={t('Fale com a nossa equipe') || ''}
          />
          <CardImage
            b_container={b_containerName}
            title={t(
              'Por que investir em treinamento para corretor de imóveis?'
            )}
            imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20para%20corretores.webp?alt=media&token=0b2a02cd-b93f-4ed4-9199-1d0465f181e6'
            img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20treinamento%20para%20corretores.jpg?alt=media&token=fd970c54-f8a3-42fb-ba4b-b456d61a880e'
          />
          <CardText
            b_container={b_containerName}
            b_str='1'
            title=''
            text={
              t(
                'Capacitar o corretor de imóveis é de grande relevância para uma imobiliária, construtora ou incorporadora, já que este profissional, quando preparado, pode trazer bons resultados no número de contratos e de negócios que são fechados no dia a dia.\n\nUm corretor capacitado terá mais segurança ao atender os clientes, terá na ponta da língua os detalhes dos produtos a serem vendidos e saberá as melhores formas de abordar os consumidores de acordo com o perfil de cada um.\n\nHoje em dia os clientes estão mais exigentes e mais informados com relação aos produtos que desejam. Com isso, oferecer um atendimento de qualidade é fundamental para obter sucesso nas vendas.\n\nAlém disso, os treinamentos irão oferecer um padrão de atendimento aos corretores de imóveis envolvidos, o que fará com que tenham mais credibilidade e, assim, ajudem na imagem da empresa perante o público.\n\nTer uma equipe de corretores de imóveis preparada para atender é, portanto, uma boa forma de aumentar a conversão em vendas da empresa e melhorar o atendimento ao cliente.'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='2'
            title={t('Como treinar um corretor de imóveis?') || ''}
            text={`${t(
              'Treinar um corretor de imóveis e deixá-lo bem preparado para atender aos clientes é essencial para quem busca bons resultados. É importante que este profissional conheça bem os produtos que oferece, até mesmo os detalhes, considerando que hoje em dia os consumidores são mais exigentes.\n\nPara treinar um corretor você deve definir os seguintes itens'
            )}:`}
          />
          <CardInfos
            b_container={b_containerName}
            position={1}
            name={t('Definir os objetivos')}
            text={t(
              'É fundamental definir, antes do treinamento, quais os objetivos dessa capacitação e para onde vocês querem chegar.\n\nMotivar a equipe? Aumentar as vendas? Apresentar novos produtos? Seja qual for, é importante deixar tudo muito claro para todos.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={2}
            name={t('Definir o formato')}
            text={t(
              'Em seguida, você precisa determinar qual será o formato do treinamento. É nesta hora que se define a melhor forma de capacitar o profissional.\n\nHoje em dia os treinamentos considerados tradicionais – com conteúdos extremamente teóricos – estão cada vez mais em desuso, então a dica é apostar em capacitações mais dinâmicas, como por exemplo, treinamentos gamificados, o quais promovem mais interação entre os participantes, além de serem mais divertidos.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={3}
            name={t('Definir a logística do treinamento')}
            text={t(
              'Agora você precisa pensar no seguinte: quantos corretores de imóveis serão treinados? Será um espaço físico ou online? Se for a primeira opção, onde será e qual o tamanho do local?\n\nPlanejar bem a logística do treinamento pode ser determinante para o sucesso do evento. O corretor de imóveis costuma ser exigente, então pense bastante em como será essa capacitação.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={4}
            name={t('Definir as ferramentas do treinamento')}
            text={t(
              'Como bem sabemos, vivemos um momento em que a tecnologia está em alta e isso não deve ser ignorado em seu treinamento. Portanto, traga isso a seu favor e utilize ferramentas modernas para a capacitação.\n\nA ideia é facilitar a vida do profissional, então invista no uso do celular, item este que hoje é indispensável para qualquer cidadão. Inclusive, procure inserir essa tecnologia não apenas no treinamento, mas também como ferramenta de trabalho para estes profissionais.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={5}
            name={t('Definir como serão avaliados os resultados')}
            text={t(
              'Para finalizar, o treinamento precisa ser avaliado e mensurado, com o intuito de identificar quais as possíveis melhorias e os pontos fortes da capacitação.\n\nPense nas possíveis métricas ou fatores que podem ser analisados na avaliação, como por exemplo, o impacto no número de contratos fechados após o evento, novos clientes conquistados ou os efeitos na taxa de satisfação dos consumidores, entre outros.'
            )}
          />
          <CardText
            b_container={b_containerName}
            b_str='3'
            title={
              t('O que é preciso para ser um bom corretor de imóveis?') || ''
            }
            text={`${t(
              'Atuar como corretor de imóveis é um grande desafio, uma vez que esta profissão exige ter conhecimento de diversas técnicas e, também, saber como colocá-las em prática.\n\nPara quem está começando nesta área e busca o sucesso na corretagem, oferecemos 4 dicas'
            )}:`}
          />
          <CardNumber
            b_container={b_containerName}
            b_str='1'
            title={t('Invista em conhecimento')}
            text={t(
              'Investir em cursos que ampliem o seu conhecimento nunca é demais. Portanto, procure se capacitar bastante, esteja atualizado com relação às inovações que esse mercado possui.\n\nAproveite os treinamentos que a sua empresa possa vir a oferecer, participe de palestras, feiras e até mesmo webinars que, em muitos casos, são gratuitos. É sempre bom ouvir pessoas que já têm sucesso na área.'
            )}
          />
          <CardNumber
            b_container={b_containerName}
            b_str='2'
            title={t('Trabalhe o seu marketing pessoal')}
            text={t(
              'Esta é uma estratégia essencial para qualquer profissional, independente da área em que atua. Trabalhe bem a sua imagem para que isso seja uma estratégia de vendas de imóveis.\n\nSaiba como se apresentar ao cliente, tenha um bom comportamento e postura ao atendê-lo, comunique-se bem e demonstre segurança. Isso pode ser determinante para realizar uma venda.'
            )}
          />
          <CardNumber
            b_container={b_containerName}
            b_str='3'
            title={t('Conheça o perfil do seu cliente')}
            text={t(
              'Oferecer um bom atendimento é o primeiro passo rumo à uma venda de sucesso. Para isso, é importante conhecer bem o perfil do seu cliente, saber o que ele procura e quais as preocupações dele.\n\nDesta forma, você saberá como pode convencê-lo a adquirir o seu produto e terá bons argumentos para conquistar o consumidor.'
            )}
          />
          <CardNumber
            b_container={b_containerName}
            b_str='4'
            title={t('Tenha suas finanças em dia')}
            text={t(
              'Sabemos que esta carreira nem sempre oferece uma renda fixa. Sendo assim, tenha um bom controle financeiro para não correr o risco de passar apuros com relação ao seu dinheiro.\n\nÉ fundamental possuir uma boa reserva financeira, caso você tenha, eventualmente, dificuldades em realizar novas vendas. Estar bem preparado é importante para evitar problemas no futuro.'
            )}
          />
          <CardText
            b_container={b_containerName}
            b_str='4'
            title={
              t(
                'Quais são os principais tipos de treinamentos para o corretor de imóveis?'
              ) || ''
            }
            text={
              t(
                'O setor de imóveis é um dos mais complexos para quem trabalha com vendas. Afinal, não é nada simples vender um apartamento ou casa. Para atuar nessa carreira e conquistar o sucesso, é sempre bom se capacitar e se atualizar.\n\nMas quais os principais tipos de treinamento para o corretor de imóveis?\n\nAlém do curso obrigatório, de Técnico em Transações Imobiliárias (TTI), o qual garante o CRECI (Certificado que permite a atuação como corretor de imóveis) e o diploma, há outros cursos que podem agregar bastante conhecimento a este profissional.'
              ) || ''
            }
          />
          <CardInfos
            b_container={b_containerName}
            position={1}
            name={t('Treinamento de vendas')}
            text={t(
              'Um corretor é, a bem da verdade, um vendedor. Portanto, é essencial que ele domine as técnicas de venda e saiba como abordar os clientes de acordo com o perfil de cada um.\n\nHoje em dia um vendedor se destaca quando possui grandes habilidades interpessoais, então invista em cursos de atendimento e de marketing pessoal para conquistar e fidelizar os consumidores.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={2}
            name={t('Pós-graduação e MBA')}
            text={t(
              'Para quem já possui o diploma, investir em uma especialização pode dar um upgrade no currículo e fazer com que tenha mais destaque no mercado de trabalho.\n\nHá diversos cursos para escolher, como Gestão do Mercado Imobiliário, por exemplo. Além de desenvolver novas atividades e possuir mais conhecimento, uma especialização pode ser uma boa troca de experiências entre os alunos.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={3}
            name={t('Palestras e workshops')}
            text={t(
              'Estes casos ensinam a adotar práticas importantes no dia a dia dos profissionais. Além disso, o participante poderá conhecer cases de sucesso, ter novas ideias e aprender técnicas interessantes.\n\nOutra possibilidade é assistir lives e webinares pelas redes sociais ou outras plataformas digitais. Muitos deles são gratuitos e apresentados por especialistas na área.'
            )}
          />
          <ContatoCaller
            b_container={b_containerName}
            b_str='2'
            title={
              t(
                'Capacite seus corretores com treinamentos de vendas gamificados'
              ) || ''
            }
            buttonText={t('Fale com a nossa equipe') || ''}
          />
        </Style>
      </main>
      <Footer />
    </>
  );
};
export default TreinamentoParaCorretorDeImoveis;
