export const validateEmail = (aux: any) => {
  const re =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(aux).toLowerCase());
};

export const validateLinkedIn = (linkedIn: any) => {
  const reg =
    /(http(s)?:\/\/.)?(www\.)?linkedin\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;
  return reg.test(String(linkedIn).toLowerCase());
};
