import styled from 'styled-components';

const CardRowContainerStyle = styled.div`
  margin: 80px auto;
  display: flex;
  max-width: 100%;
  position: relative;
  @media screen and (min-width: 1200px) {
    max-width: 80%;
    margin-left: 5%;
    margin-right: 15%;
  }
  h2 {
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #e20c18;
    white-space: pre-wrap;
  }
  p {
    font-size: ${props => props.theme.fontSize.default};
    color: #7283ba;
    margin-bottom: 47px;
    white-space: pre-wrap;
  }
  .svg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    overflow: hidden;
    z-index: -1;
  }
  .column-right {
    width: 55%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 70px;
    .list-card-containers {
      margin-top: 30px;
      .list-card {
        background: #fff;
        border: 1px solid #e1dfff;
        border-radius: 15px;
        display: flex;
        padding: 12px;
        padding-right: 70px;
        margin-top: 10px;
        margin-bottom: 15px;
        align-items: center;

        .card-image {
          width: 90px;
          height: 90px;
          background: #fce4e5;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 20px;
          border-radius: 15px;
          margin: 0 auto;
        }
        .card-info {
          margin-left: 20px;
          .card-title {
            color: #09206a;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .card-text {
            color: #7283ba;
          }
        }
      }
    }
  }
  .column-left {
    position: relative;
    width: 55%;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      position: relative;
    }
  }
  @media (max-width: 1200px) {
    .column-right {
      margin-right: 5%;
      .list-card {
        padding-right: 12px;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    p {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
  }
  @media screen and (max-width: 800px) {
    .cards-container {
      display: block;
      .card-info {
        height: 1450px;
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    padding: 0 35px;
    h2 {
      font-size: 30px;
    }
    .column-left {
      width: 100%;
    }
    .column-right {
      width: 100%;
      margin-left: 0;
      .list-card {
        align-items: center;
        padding-right: 0;
        display: grid;
        grid-template-columns: 60px 1fr;
        .card-image {
          width: 60px;
          height: 60px;
          padding: 10px 13px;
        }
        .card-info {
          margin-left: 12px;
          .card-title {
            font-size: 18px;
            margin-bottom: 5px;
          }
          .card-text {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 560px) {
    p {
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
    .column-right {
      .list-card {
        .card-info {
          .card-title {
            font-size: 16px;
          }
          .card-text {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export default CardRowContainerStyle;
