import { Check } from '@styled-icons/bootstrap/Check';
import parse from 'html-react-parser';
import Style from './styles';
interface CheckCardProps {
  b_container: string;
  b_str: string;
  title: string;
  text: string;
}

const CheckCard = ({
  title,
  text,
  b_container,
  b_str
}: CheckCardProps): JSX.Element => {
  return (
    <Style>
      <div className='container-check'>
        <Check />
      </div>
      <span className='dados'>
        <div
          className='title'
          b-container={`${b_container}`}
          b-str={`titulo_check_${b_str}`}>
          {title && parse(title)}
        </div>
        <p
          className='text'
          b-container={`${b_container}`}
          b-str={`texto_check_${b_str}`}>
          {text && parse(text)}
        </p>
      </span>
    </Style>
  );
};

export default CheckCard;
