import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardImage from '../../components/cardImage';
import CardInfos from '../../components/cardInfos';
import CardText from '../../components/cardText';
import CheckCard from '../../components/checkText';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderNav from '../../components/headerNav';
import ContatoCaller from '../../components/textButtonContainer';
import i18n from '../../utils/translate';
import Style from './styles';

const MotivacaoEquipeVendas = (): JSX.Element => {
  const b_containerName = 'motivacao';
  const { t } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t('Motivação da Equipe de Vendas - Play2sell')}</title>
        <meta
          name='description'
          content='Motivação da Equipe sua equipe se tornou muito mais fácil com o treinamento inovador da Play2sell.'
        />
        <meta name='robots' content='follow, index' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Motivação da Equipe de Vendas - Play2sell'
        />
        <meta
          property='og:description'
          content='Motivação da Equipe sua equipe se tornou muito mais fácil com o treinamento inovador da Play2sell.'
        />
        <meta
          property='og:url'
          content='https://play2sell.com/sales-team-motivation/'
        />
        <meta
          property='article:publisher'
          content='https://www.facebook.com/play2sell/'
        />
        <meta property='og:updated_time' content='2021-02-18T10:55:38-03:00' />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20motiva%C3%A7%C3%A3o%20para%20equipe%20de%20vendas.jpg?alt=media&token=466d90b6-fe59-4dab-be5c-14f17eb1eff5'
        />
        <meta
          property='og:image:secure_url'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20motiva%C3%A7%C3%A3o%20para%20equipe%20de%20vendas.jpg?alt=media&token=466d90b6-fe59-4dab-be5c-14f17eb1eff5'
        />
        <meta property='og:image:width' content='1024' />
        <meta property='og:image:height' content='683' />
        <meta property='og:image:alt' content='Motivação da Equipe' />
        <meta property='og:image:type' content='image/jpeg' />

        <meta
          name='twitter:title'
          content='Motivação da Equipe de Vendas - Play2sell'
        />
        <meta
          name='twitter:description'
          content='Motivação da Equipe sua equipe se tornou muito mais fácil com o treinamento inovador da Play2sell.'
        />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20motiva%C3%A7%C3%A3o%20para%20equipe%20de%20vendas.jpg?alt=media&token=466d90b6-fe59-4dab-be5c-14f17eb1eff5'
        />
      </Helmet>
      <HeaderNav />
      <Header
        b_container={b_containerName}
        title={t('Motivação da equipe de vendas')}
        subtitulo={t('Motivação da equipe de vendas')}
      />
      <main>
        <Style className='container-main'>
          <ContatoCaller
            b_container={b_containerName}
            b_str='1'
            title={t('Motive seus colaboradores e aumente suas vendas') || ''}
            buttonText={t('Agende Uma Reunião') || ''}
          />
          <CardImage
            b_container={b_containerName}
            title={t(
              'Por que investir em treinamento para corretor de imóveis?'
            )}
            imgWebp='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20motiva%C3%A7%C3%A3o%20para%20equipe%20de%20vendas.webp?alt=media&token=cb123327-46f1-484f-839f-f75a5db89ff5'
            img='https://firebasestorage.googleapis.com/v0/b/play2sell---site.appspot.com/o/other_images%2FSEO%2Fp%C3%A1gina%20motiva%C3%A7%C3%A3o%20para%20equipe%20de%20vendas.jpg?alt=media&token=466d90b6-fe59-4dab-be5c-14f17eb1eff5'
          />
          <CardText
            b_container={b_containerName}
            b_str='1'
            title=''
            text={
              t(
                'A área comercial é uma das mais exigidas no mercado de trabalho, já que se espera que traga grandes resultados de vendas e alta performance dos profissionais. Neste sentido, motivar a equipe é essencial para que as empresas atinjam os objetivos esperados.\n\nInvestir na motivação da equipe traz maior produtividade, melhora no clima organizacional e engajamento dos profissionais no que se refere a alcançar o sucesso junto à empresa.\n\nAlém disso, cada trabalhador é propagador da imagem da corporação. Se a empresa é um bom local de trabalho, muito provavelmente isso será disseminado pelos funcionários.\n\nPara motivar a equipe de vendas, é importante investir em capacitações, na estrutura da empresa, na comunicação entre a empresa e os vendedores e, até mesmo em recompensas por cada meta atingida.\n\nTambém é interessante dar feedbacks constantes, para que os profissionais saibam bem quais seus erros e acertos. Em contrapartida, saiba ouvir seus funcionários e procure entender o que eles têm a dizer.\n\nCom uma equipe motivada e engajada a sua empresa alcançará o sucesso com mais rapidez.'
              ) || ''
            }
          />
          <CardText
            b_container={b_containerName}
            b_str='2'
            title={t('Como motivar uma equipe de vendas?') || ''}
            text={`${t(
              'A falta de motivação da equipe reflete diretamente nos resultados da empresa. Afinal, quem trabalha sem vontade não produz da mesma forma. Mesmo os profissionais mais experientes precisam estar motivados para desenvolver um trabalho de qualidade.\n\nPara isso, existem formas de explorar o potencial de cada vendedor ao máximo e criar um ambiente mais agradável e propício para o crescimento deste profissional.\n\nConfira 4 dicas de como motivar uma equipe de vendas'
            )}:`}
          />
          <CardInfos
            b_container={b_containerName}
            position={1}
            name={t('Invista em treinamentos') || ''}
            text={t(
              'O profissional que conta com a possibilidade de se capacitar ainda mais fica mais motivado e preparado para desenvolver seus serviços no dia a dia. O sentimento que se tem é “a empresa investe em mim” é benéfico tanto para o profissional quanto para a empresa.\n\nPorém, é importante pensar no formato deste treinamento, já que os tradicionais estão em desuso. Procure por capacitações mais dinâmicas, que aliem o aprendizado à diversão, como os treinamentos de vendas gamificados, por exemplo.'
            )}
          />
          <CardInfos
            b_container={b_containerName}
            position={2}
            name={t('Ofereça boas comissões')}
            text={
              t(
                'Todo mundo gosta de ganhar dinheiro, certo? Então nada melhor do que usar as comissões como recurso para motivar a equipe de vendas. Com isso, o time estará mais engajado na busca por melhores resultados.\n\nE, claro, ofereça comissões competitivas, equivalentes com o mercado, para que realmente sirva como um incentivo para que os profissionais produzam mais e melhor.'
              ) || ''
            }
          />
          <CardInfos
            b_container={b_containerName}
            position={3}
            name={t('Estabeleça reuniões periódicas com a equipe')}
            text={
              t(
                'É fundamental realizar reuniões com sua área comercial para estabelecer os objetivos, dar feedbacks do trabalho desenvolvido e saber quais as dificuldades de cada um.\n\nQuando o profissional tem a possibilidade de ser ouvido, ele se sente mais confiante e preparado para desenvolver suas funções. Além disso, este é um ótimo momento para que os líderes falem e exerçam sua influência perante os colaboradores.'
              ) || ''
            }
          />
          <CardInfos
            b_container={b_containerName}
            position={4}
            name={t('Ofereça liberdade de trabalho')}
            text={
              t(
                'Claro que toda empresa tem as suas regras e é essencial cumpri-las com certo rigor, mas dar liberdade para que o profissional desenvolva sua autogestão pode trazer mais confiança e motivação a ele.\n\nPermita que cada vendedor tenha sua própria rotina, respeitando sempre os horários pré-determinados, como de reuniões ou de treinamentos, por exemplo.'
              ) || ''
            }
          />
          <CardInfos
            b_container={b_containerName}
            position={5}
            name={t('Reconheça o trabalho da equipe')}
            text={
              t(
                'Todos gostam de ser reconhecidos por desempenhar um bom trabalho. Mas esse reconhecimento pode ir além dos elogios e das palmas.\n\nO incentivo financeiro é uma ótima forma de motivar a equipe de vendas, mas existem outras maneiras de reconhecer um bom profissional, como diplomas, troféus e placas.\n\nAinda é possível usar a criatividade: a empresa pode oferecer como prêmio um smartphone, um notebook, uma viagem, um jantar, entre outros presentes que com certeza irão motivar o seu time.'
              ) || ''
            }
          />
          <CardInfos
            b_container={b_containerName}
            position={6}
            name='Comemore as conquistas'
            text={`Quando todas as metas são batidas, por que não fazer uma comemoração? Seja ela um coquetel, com alguns salgadinhos e bebidas, ou até mesmo uma festa mesmo, com música e diversão.

Esta é uma forma não só de motivar, mas também de integrar e unir os trabalhadores da empresa, que terão um momento de descontração e poderão se conhecer melhor.`}
          />
          <CardText
            b_container={b_containerName}
            b_str={'3'}
            title={
              t('Quais as melhores técnicas para motivação da equipe') || ''
            }
            text={
              t(
                'Para a motivação da equipe você precisa entender os desejos e necessidades que ela possui no dia a dia. Oferecer um ambiente seguro, confortável e com equipamentos adequados podem ajudar, mas só isso não basta.\n\nPortanto, apresentamos algumas técnicas para que você conquiste a motivação da equipe'
              ) + ':'
            }
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'1'}
            title={t('Demonstre seu interesse e apoio')}
            text={t(
              'Mostre à sua equipe que você se preocupa com o bem estar e motivação de todos.\nProcure ouvir o que os funcionários têm a dizer e ofereça formas de ajudá-los no que precisam.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'2'}
            title={t('Mostre o papel de cada um na empresa')}
            text={t(
              'É importante que todos saibam quais os objetivos que a empresa quer alcançar.\nÉ tão importante quanto é que os colaboradores entendam qual é o papel de cada um e sintam a sua importância dentro do projeto.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'3'}
            title={t('Incentive as ideias e sugestões do funcionários')}
            text={t(
              'Uma forma de motivação da equipe é ouvir atentamente as ideias e sugestões dos colaboradores.\nSaber que suas ideias são levadas em consideração é um ótimo primeiro passo.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'4'}
            title={t('Integre as equipes')}
            text={t(
              'Nada de "cada um no se quadrado": integrar diferentes equipes é bastante saudável e traz um sentimento de união e motivação em equipe.\nPromova reuniões e incentive que cada área exponha suas ideias e dificuldades do dia a dia.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'5'}
            title={t('Ofereça benefícios')}
            text={t(
              'Neste caso, você pode estabelecer parcerias com academias, escolas de inglês, restaurantes, etc.\nAssim, seus colaboradores poderão ter bons descontos e se sentirão mais felizes com a empresa.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'6'}
            title={t('Apresente metas claras')}
            text={t(
              'Clareza nas metas é essencial para que a equipe atinja os resultados desejados.\nTodos precisam entender bem essas metas para que desenvolvam um trabalho eficaz e adequado.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'7'}
            title={t('Reconheça a eficiência da sua equipe')}
            text={t(
              'Todos gostam de se sentir valorizados, então procure fazer elogios em público, comemore com eles os resultados alcançados e demonstre sua satisfação com o trabalho de cada um.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'8'}
            title={t('Dê feedbacks')}
            text={t(
              'Independente de ser algo positivo e negativo, todo colaborador quer ter um feedback dos serviços que presta.\nAssim saberá como pode melhorar ou o que deve continuar fazendo.'
            )}
          />
          <CheckCard
            b_container={b_containerName}
            b_str={'9'}
            title={t('Seja um exemplo positivo')}
            text={t(
              'Com essas técnicas você poderá alcançar bons resultados no que se refere à motivação em equipe.\nLembre-se: quanto mais os seus colaboradores estiverem satisfeitos, melhores resultados a sua empresa terá.'
            )}
          />
          <ContatoCaller
            b_container={b_containerName}
            b_str={'2'}
            title={
              t(
                'Invista na motivação dos seus colaboradores com treinamentos gamificados'
              ) || ''
            }
            buttonText={t('Agende Uma Reunião') || ''}
          />
        </Style>
      </main>
      <Footer />
    </>
  );
};

export default MotivacaoEquipeVendas;
