import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #fff;
  color: #1a1a1a;
  font: 400 16px ${(props: any) => props.theme.fontFamily.title};
  a {
    color: #fff;
    text-decoration: none;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  input {
    border: none;
    outline: none;
  }
  img {
    color: #fff;
    font: 400 16px ${(props: any) => props.theme.fontFamily.title}, sans-serif;
  }
  button {
    background: #fff;
    border: none;
    cursor: pointer;
  }

  .container-main {
    max-width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 1200px) {
      max-width: 70%;
    }
  }
  .container-form {
    max-width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 1200px) {
      max-width: 50%;
    }
  }

  .container-narrow {
    max-width: 95%;
    @media screen and (min-width: 1200px) {
      max-width: 70%;
    }
  }
  .container-wide {
    max-width: 100%;
    @media screen and (min-width: 1200px) {
      max-width: 80%;
    }
  }
  .b_style {
    outline: none;
  }
  #redCircle {
    @media screen and (max-width: 880px) {
      display: none;
    }
  }
  @keyframes movingaround {
    33% {
      transform: translate(50px, 21px) rotate(10deg);
    }
    66% {
      transform: translate(28px, 42px) rotate(5deg);
    }
  }
  @keyframes movingaroundinverted {
    33% {
      transform: translate(50px, 21px) rotate(10deg) scaleX(-1);
    }
    66% {
      transform: translate(28px, 42px) rotate(5deg) scaleX(-1);
    }
  }
}
`;
