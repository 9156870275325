import styled from 'styled-components';

const integrador = styled.div`
  max-width: 100%;
  margin: 10px auto;
  @media screen and (min-width: 1200px) {
    max-width: 95%;
  }
  font-family: ${props => props.theme.fontFamily.title};
  .integrador {
    .img {
      height: 180px;
      padding: 30%;
      margin-right: 30%;
      margin-left: 30%;
      margin-bottom: 25%;
      margin-top: -5%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 180px;
        max-height: 210px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .integrador {
      h1 {
        font-size: 40px;
        padding: 10px;
        font-weight: 100;
        color: #e20c18;
        text-align: center;
      }
      h4 {
        font-size: 18;
      }
    }
  }
`;
export default integrador;
