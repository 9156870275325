import styled from 'styled-components';

const GamificacaoStyle = styled.div`
  padding: 4em 0;
  background: #ffff;
  max-width: 70%;
  margin: 0 auto;
  .infos-card {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 60px;
    .card-info {
      width: calc(50% - 30px);
      border-radius: 14px;
      box-shadow: 0 3px 6px #00000020;
      padding: 60px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    height: 100px;
    span:last-child {
      margin-left: 15px;
      font-size: 30px;
      color: ${props => props.theme.colors.textTitle};
      font-weight: 600;
    }
  }
  .text {
    font-size: ${props => props.theme.fontSize.default};
    line-height: 1.7;
    color: #7283ba;
    white-space: pre-wrap;
    margin-top: 27px;
  }
  .beneficios {
    margin-top: 25px;
    h1 {
      font-size: 30px;
      color: ${props => props.theme.colors.textTitle};
      font-weight: 500px;
    }

    p {
      margin-top: 10px;
      color: #7283ba;
    }
    .opcoes {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 1100px) {
    max-width: 90%;
    .infos-card {
      flex-direction: column;
      .card-info {
        width: 100%;
        padding: 25px;
      }
    }
    .text {
      font-size: ${props => props.theme.fontSize.defaultTablet};
    }
  }
  @media screen and (max-width: 560px) {
    max-width: 90%;
    .text {
      font-size: ${props => props.theme.fontSize.defaultMobile};
    }
  }
`;

export default GamificacaoStyle;
