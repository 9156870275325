import Styled from 'styled-components';

const CookieBoxStyle = Styled.div`
  width: 100%;
  position: fixed;
  background: #000000c0;
  bottom: 0px;
  padding: 20px;
  margin: 0 auto;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  span {
    width: 100%;
    color: #fff;
    a {
      font-weight: 600;
      color: #e20c18;
      &:hover {
        text-decoration: underline
      }
    }
  }
  .btn-accept {
    display: inline-block;
    background: #e20c18;
    padding: 10px 20px;
    margin-top: 10px;
    color: #fff;
    border-radius: 25px;
    transition: 200ms ease-in-out;
    cursor: pointer;
    &:hover {
      background: #fff;
      color: #e20c18
    }
  }
`;

export default CookieBoxStyle;
