import { useState } from 'react';
import styled from 'styled-components';

const TeamCardStyle = styled.div`
  padding-bottom: 2em;
  background: #ffff;
  margin: 0 auto;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: 116px 2fr;
  p {
    width: 100%;
  }
  span.dados {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 25px 13px;
  }
  p:first-child {
    font-size: 24px;
    color: ${props => props.theme.colors.textTitle};
    font-weight: 500;
  }
  p:last-child {
    font-size: 21px;
    color: #ff7b26;
    font-weight: 400;
  }
  span.img {
    width: 116px;
    height: 116px;
    background: #cececece;
    display: block;
    position: relative;
    border-radius: 50%;
    box-shadow: 0px 3px 25px -14px;
    border: 5px solid #fff;
    overflow: hidden;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
    .img-stopped {
      z-index: 1;
    }
    .img-moving {
      z-index: -1;
    }
  }
  .fade {
    opacity: 0;
  }
`;

interface TeamCardProps {
  img: string;
  name: string;
  job: string;
  gif?: string;
}
const TeamCard = (props: TeamCardProps) => {
  const { img, name, job, gif } = props;
  const [Ativado, setAtivado] = useState(false);

  return (
    <TeamCardStyle>
      <span
        className='img'
        onMouseEnter={() => setAtivado(true)}
        onMouseLeave={() => setAtivado(false)}>
        <img className='img-moving' src={gif} alt='gif' />
        {img ? (
          <img
            src={img}
            alt='Foto equipe'
            className={Ativado ? 'fade img-stopped' : 'img-stopped'}
          />
        ) : (
          ''
        )}
      </span>
      <span className='dados'>
        <p className='title'>{name || 'nome'}</p>
        <p className='function'>{job || 'marketing'}</p>
      </span>
    </TeamCardStyle>
  );
};

export default TeamCard;
