import Styled from 'styled-components';
const StyleMain = Styled.main`
.container-colaboradores {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
}
`;

export default StyleMain;
